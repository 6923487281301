import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import OuterSpinner from "../components/Spinner/OuterSpinner";

const RedirectToWayfless = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    window.location.href =
      "https://connect.liblynx.com/wayfless/tll?" + urlSearchParams.toString();
  }, []);

  return <OuterSpinner />;
};

export default RedirectToWayfless;
