import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as constants from "../utils/Constants";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import { fetchFileName } from "./helper_functions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Parser() {
  let query = useQuery();
  const paramId = query.get("rowId");
  const [fileName, setFileName] = React.useState();
  const type = window.location.pathname.split("/")[1];

  useEffect(() => {
    loadData();
  }, [paramId]);

  const loadData = () => {
    var token = localStorage.getItem("jwtToken");
    const headers = { Authorization: `Token ${token}` };

    trackPromise(
      axios
        .get(
          process.env.REACT_APP_SERVER_HOST_NAME +
            "/rest/" +
            type +
            "/rowId?rowId=" +
            paramId,
          { headers }
        )
        .then((response) => {
          console.log(response);
          setFileName(fetchFileName(response.data));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.replace("/validate");
          }
          console.log("failed");
        })
    );
  };

  return (
    <div>
      <OuterSpinner />
      {fileName !== null && fileName !== undefined ? (
        // <p>{"/" + type + "/" + fileName + "?rowId=" + paramId}</p>
        <Redirect to={"/" + type + "/" + fileName + "?rowId=" + paramId} />
      ) : (
        ""
      )}
    </div>
  );
}

export default Parser;
