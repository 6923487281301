import { Tabs, Tab } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdvancedMobile from "./AdvancedMobile";
import AlphabeticalMobile from "./AlphabeticalMobile";
import StandardMobile from "./StandardMobile";
import AdvancedSearch from "../SearchTab/AdvancedSearch";
function TabPanel(props) {
  const { children, currentTabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`mobile-tabpanel-${index}`}
      aria-labelledby={`mobile-tab-${index}`}
      {...other}
    >
      {currentTabIndex === index && <> {children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  currentTabIndex: PropTypes.any.isRequired,
};
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      display: "none",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#3c3c3c",
    fontFamily: '"Gotham-Medium"',
    fontSize: "17px",
    lineHeight: "30px",
    marginRight: theme.spacing(1),
    backgroundColor: "#ffffff",
    border: "0.5px solid #a4a4a4",
    "&:focus": {
      opacity: 1,
    },
    "&.MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#005d78!important",
      color: "#ffffff!important",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabscontainer: {
    "& .MuiTabs-flexContainer": {
      alignItems: "center!important",
      justifyContent: "center!important",
    },
  },
  mobileTabs: {
    color: "#3c3c3c",
    maxWidth: "100vw",
  },
  standard: {
    padding: "40px 15px",
  },
  alphabet: {
    padding: "40px 37px",
  },
}));

function MobileSearchTabs({ values, bottom, close, id }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  // if (!values) values = { search: [{ field: "LEMMA", value: "" }] };
  // console.log(`MobileSearchTabs ${id} values`, values);

  const handleChange = (event, newValue) => {
    // console.log("Mog");
    setCurrentTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.mobileTabs + " scrollbar-hidden"}>
        <StyledTabs
          value={currentTabIndex}
          onChange={handleChange}
          className={classes.tabscontainer}
          aria-label="styled tabs example"
        >
          <StyledTab label={t("Standard")} />
          <StyledTab label={t("Advanced")} />
          <StyledTab label={t("Alphabetical")} />
        </StyledTabs>
        <TabPanel
          component="div"
          currentTabIndex={currentTabIndex}
          index={0}
          className={classes.tab}
        >
          <div>
            <div className={classes.standard}>
              <StandardMobile values={values} bottom={bottom} close={close} />
            </div>
          </div>
        </TabPanel>
        <TabPanel
          component="div"
          currentTabIndex={currentTabIndex}
          index={1}
          className={classes.tab}
        >
          <div className={classes.advanced}>
            <AdvancedSearch
              id="advanced-search-mobile"
              key="advanced-search-mobile"
              variant="dark"
              mobile={true}
              values={values}
              bottom={bottom}
              close={close}
            ></AdvancedSearch>
            {/* <AdvancedMobile
              variant="dark"
              values={values}
              bottom={bottom}
              close={close}
            /> */}
          </div>
        </TabPanel>
        <TabPanel
          component="div"
          currentTabIndex={currentTabIndex}
          index={2}
          className={classes.tab}
        >
          <div className={classes.alphabet}>
            <AlphabeticalMobile bottom={bottom} close={close} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default MobileSearchTabs;
