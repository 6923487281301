import React from "react";
import {
  makeStyles,
  Paper,
  Select,
  Button,
  InputLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSearchURL } from "../../utils/helper_functions";
import { SEARCH_FIELDS, SEARCH_TYPE } from "../../utils/Constants";
const useStyles = makeStyles((theme) => ({
  searchpaper: {
    backgroundColor: "#ffffff",
    height: "55px!important",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #a4a4a4",
    marginBottom: "20px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 5,
  },
  category: {
    paddingLeft: theme.spacing(2),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  categorytag: {
    paddingLeft: "20px",
    lineHeight: "30px",
    fontFamily: '"Gotham-Book"',
  },
  abtn: {
    fontFamily: "'Gotham-Medium'",
    fontSize: "14px",

    maxWidth: "50px!important",
    maxHeight: "50px!important",
    minWidth: "50px!important",
    minHeight: "50px!important",
    backgroundColor: "#ffffff",

    border: "0.5px solid #a4a4a4!important",
  },
  btnContainer: {
    padding: "6px 5px",
    "&:hover": {
      backgroundColor: "#007596!important",
      "& $abtn": {
        color: "#ffffff!important",
      },
    },
  },
  alphabetBtn: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 5,
  },
}));

export default function AlphabeticalMobile(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [type, setType] = React.useState("LEMMA");
  let history = useHistory();
  const submitAlphabet = (e) => {
    props.close();
    const query = getSearchURL(
      SEARCH_TYPE.ALPHABETICAL_SEARCH,
      {},
      SEARCH_FIELDS[type],
      e.currentTarget.value
    );
    history.push({
      pathname: "/results",
      search: query,
    });
  };
  const alphabetButtons = (s, e) => {
    let btns = [];
    for (let i = s.charCodeAt(0); i <= e.charCodeAt(0); i++) {
      btns.push(String.fromCharCode(i));
    }
    return btns.map((e) => {
      return (
        <div className={classes.btnContainer}>
          <Button className={classes.abtn} value={e} onClick={submitAlphabet}>
            {e}
          </Button>
        </div>
      );
    });
  };
  const fvalue = ["LEMMA", "AUTHOR_CITED", "INDEX_LIBRORUM"];
  const fname = ["Lemma", "Author Cited", "Index Librorum"];
  const handleInput = (e) => {
    let value = e.currentTarget.value;
    setType(value);
  };
  return (
    <>
      <Paper square elevation={0} className={classes.searchpaper}>
        <InputLabel
          htmlFor="field"
          shrink={true}
          className={classes.categorytag}
          style={{ color: "#000000" }}
        >
          {t("Category")}:
        </InputLabel>
        {/* <CustomDropDownMobile  id='field' name='field' row={55} title='Category' optionsList={fvalue}
                                onChange={(e) => this.inputHandler(e, 55)}
                                nameList={fname}
                                defaultSelected={this.state.temp.search[0].field}
                            >

                            </CustomDropDownMobile> */}
        <Select
          native
          id="field"
          name="field"
          value={type}
          fullWidth
          onChange={(e) => handleInput(e)}
        >
          {fvalue.map((el, index) => {
            return <option value={el}>{t(fname[index])}</option>;
          })}
        </Select>
      </Paper>
      <div className={classes.alphabetBtn}>{alphabetButtons("A", "Z")}</div>
    </>
  );
}
