import React from "react";
import {
  withStyles,
  Paper,
  InputBase,
  FormControl,
  Input,
  InputLabel,
  Button,
  Typography,
  Select,
} from "@material-ui/core";
import SearchButtonMobile from "./SearchButtonMobile";
import SearchListMobile from "./SearchListMobile";
import { withRouter } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import { getSearchURL } from "../../utils/helper_functions";
import { SEARCH_TYPE } from "../../utils/Constants";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  volContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    lineHeight: 1,
  },
  volItem: {
    width: "25%",
    paddingRight: "10px",
  },
  volpaper: {
    backgroundColor: "#ffffff",
    marginTop: "5px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #a4a4a4",
  },
  searchpaper: {
    backgroundColor: "#ffffff",
    height: "55px!important",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #a4a4a4",
    marginBottom: "20px",
  },
  input: {
    paddingLeft: theme.spacing(4),
    "& .MuiInputBase-input::placeholder": {
      opacity: "unset !important",
      color: "#3c3c3c !important",
    },
  },
  category: {
    paddingLeft: theme.spacing(2),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: 1,
    },
  },
  categorytag: {
    paddingLeft: "20px",
    lineHeight: 1,
    fontFamily: '"Gotham-Book"',
  },
  categoryField: {
    lineHeight: 1,
  },
  formbtn: {
    "& button:hover": {
      backgroundColor: "#007596!important",
      color: "#ffffff!important",
      "& svg path": {
        stroke: "#ffffff",
      },
    },
  },
  button: {
    color: "#121212!important",
    backgroundColor: "#ffffff",
    border: "0.5px solid #a4a4a4",
    margin: "0 10px 0px 0",
  },
  intInputs: {
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Medium","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  searchbtn: {
    width: "100%",
  },
  rowName: {
    fontSize: "17px",
    fontFamily: '"Gotham-Medium"',
    lineHeight: "25px",
    width: "100%",
    marginBottom: "20px",
  },
  rowContainer: {
    padding: "30px 16px 20px",
    maxWidth: "100%",
  },

  bottom: {
    position: "fixed",
    bottom: "0",
    backgroundColor: "#ffffff",
    // marginBottom: "20px",
    marginTop: "100px",
    width: "100%",
    height: "80px",
    padding: "12px 37px",
    left: 0,
    fontFamily: "'Gotham-Medium'!important",
  },
});

class AdvancedMobile extends React.Component {
  state = {
    temp: {
      search: [
        {
          field: "FULL_TEXT",
          value: "",
        },
      ],
    },
    searchDetails: [],
    intvalue: {},
  };

  handleChange = (e, idx) => {
    console.log(e);
    if (["value"].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else if (["field" + idx].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx]["field"] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else if (["searchQualifier" + idx].includes(e.target.name)) {
      let newArr = [...this.state.searchDetails]; // copying the old datas array
      newArr[idx]["searchQualifier"] = e.target.value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        searchDetails: newArr,
      }));
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  inputHandler = (e, id) => {
    if (["value"].includes(e.target.name)) {
      let name = e.target.name;
      let value = e.target.value;

      let newArr = { ...this.state.temp }; // copying the old datas array
      newArr.search[0][name] = value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        temp: newArr,
      }));
    } else if (["field"].includes(e.target.name)) {
      let name = "field";
      let value = e.target.value;

      let newArr = { ...this.state.temp }; // copying the old datas array
      newArr.search[0][name] = value; // replace e.target.value with whatever you want to change it to
      this.setState(() => ({
        temp: newArr,
      }));
    }
  };
  handleint = (e) => {
    if (["volume", "paragraph", "page", "line"].includes(e.target.name)) {
      let name = e.target.name;
      let value = e.target.value;

      let newArr = { ...this.state.intvalue }; // copying the old datas array
      // replace e.target.value with whatever you want to change it to
      newArr[name] = value;
      this.setState(() => ({
        intvalue: newArr,
      }));
    }
  };
  addNewRow = (e) => {
    let i = 0;
    if (
      Array.isArray(this.state.searchDetails) &&
      this.state.searchDetails.length
    ) {
      i = parseInt(
        this.state.searchDetails[this.state.searchDetails.length - 1].index + 1
      );
    }
    this.setState((prevState) => ({
      searchDetails: [
        ...prevState.searchDetails,
        {
          index: i,
          value: "",
          searchQualifier: "AND",
          field: "FULL_TEXT",
        },
      ],
    }));
  };
  clearSearch = (e) => {
    this.setState({
      temp: {
        search: [
          {
            field: "FULL_TEXT",
            value: "",
          },
        ],
      },
      searchDetails: [],
      intvalue: {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let sd = this.state.searchDetails;

    sd.map((el) => {
      delete el.index;
      return "";
    });

    let formdata = this.state.temp;

    formdata.search.push(...sd);
    formdata.search = formdata.search.filter(function (element) {
      return element !== undefined;
    });

    if (
      !isNaN(parseInt(this.state.intvalue.volume)) &&
      parseInt(this.state.intvalue.volume) !== undefined &&
      parseInt(this.state.intvalue.volume) !== null
    ) {
      formdata.volume = parseInt(this.state.intvalue.volume);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.paragraph)) &&
      parseInt(this.state.intvalue.paragraph) !== undefined &&
      parseInt(this.state.intvalue.paragraph) !== null
    ) {
      formdata.paragraph = parseInt(this.state.intvalue.paragraph);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.page)) &&
      parseInt(this.state.intvalue.page) !== undefined &&
      parseInt(this.state.intvalue.page) !== null
    ) {
      formdata.page = parseInt(this.state.intvalue.page);
    }
    if (
      !isNaN(parseInt(this.state.intvalue.line)) &&
      parseInt(this.state.intvalue.line) !== undefined &&
      parseInt(this.state.intvalue.line) !== null
    ) {
      formdata.line = parseInt(this.state.intvalue.line);
    }
    let indexflag = true;
    formdata.search.map((el) => {
      if (el.field !== "INDEX_LIBRORUM") {
        indexflag = false;
      }
      return indexflag;
    });
    const query = getSearchURL(SEARCH_TYPE.ADVANCE_SEARCH, formdata);
    this.props.history.push({
      pathname: "/results",
      search: query,
    });
  };
  deteteRow = (index) => {
    this.setState({
      searchDetails: this.state.searchDetails.filter(
        (s, sindex) => index !== sindex
      ),
    });
  };

  clickOnDelete(record) {
    this.setState({
      searchDetails: this.state.searchDetails.filter((r) => r !== record),
    });
  }
  componentWillMount = () => {
    let searchData = this.state.temp;
    let searchDetailsData = [];
    let intvaluesData = this.state.intvalue;

    let propdata = this.props?.values;
    if (this.props?.values !== undefined && this.props?.values !== null) {
      if (
        propdata?.search &&
        propdata?.search.length &&
        propdata?.search[0]?.value !== undefined &&
        propdata?.search[0]?.value !== null
      ) {
        searchData.search[0].value = propdata.search[0].value;
      }
      if (
        propdata?.search &&
        propdata?.search.length &&
        propdata?.search[0]?.field !== undefined &&
        propdata?.search[0]?.field !== null
      ) {
        searchData.search[0].field = propdata.search[0].field;
      }
      if (
        propdata?.search &&
        propdata?.search.length &&
        propdata.search.length > 1
      ) {
        propdata.search.map((el, i) => {
          if (i > 0) {
            let tempData = {};
            tempData.index = i - 1;
            if (el.value !== undefined && el.value !== null) {
              tempData.value = el.value;
            }
            if (el.field !== undefined && el.field !== null) {
              tempData.field = el.field;
            }
            if (
              el.searchQualifier !== undefined &&
              el.searchQualifier !== null
            ) {
              tempData.searchQualifier = el.searchQualifier;
            }
            searchDetailsData.push(tempData);
          }
        });
      }
      if (propdata?.volume !== undefined && propdata?.volume !== null) {
        intvaluesData.volume = propdata.volume;
      }
      if (propdata?.paragraph !== undefined && propdata?.paragraph !== null) {
        intvaluesData.paragraph = propdata.paragraph;
      }
      if (propdata?.line !== undefined && propdata?.line !== null) {
        intvaluesData.line = propdata.line;
      }
      if (propdata?.page !== undefined && propdata?.page !== null) {
        intvaluesData.page = propdata.page;
      }
      this.setState({
        search: searchData,
        searchDetails: searchDetailsData,
        intvalue: intvaluesData,
      });
    }
  };
  render() {
    const { t, i18n } = this.props;

    let { searchDetails } = this.state;

    const { classes } = this.props;
    let bgcolor = "#ffffff";
    if (this.props.variant === "dark") {
      bgcolor = "#121212";
    } else {
      bgcolor = "#ffffff";
    }
    const fvalue = ["FULL_TEXT", "LEMMA", "CITATION", "INDEX_LIBRORUM"];
    const fname = ["Full Text", "Lemma", "Citation", "Index Librorum"];

    const addRowButton = (
      <Button
        className={classes.button + " addrow gm text-unset"}
        style={{ color: bgcolor }}
        onClick={this.addNewRow}
        startIcon={
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2913 6.0212L5.63449 6.0212L5.63449 0.364381"
              stroke="black"
              strokeWidth="1.5"
            />
            <path
              d="M-0.000276499 6.00028L5.65653 6.00028L5.65653 11.6571"
              stroke="black"
              strokeWidth="1.5"
            />
          </svg>
        }
      >
        {t("Add Row")}
      </Button>
    );

    const volSearchForm = (
      <div className={classes.volContainer}>
        <div className={classes.volItem}>
          <InputLabel
            htmlFor="vol1"
            shrink={true}
            className="gm"
            style={{ color: "#000000" }}
          >
            <Trans i18nKey="volume">Volume</Trans>
          </InputLabel>
          <Paper square elevation={0} className={classes.volpaper}>
            <FormControl>
              <Input
                type="number"
                className={classes.intInputs}
                id="vol1"
                onChange={(e) => this.handleint(e)}
                name="volume"
                value={
                  this.state.intvalue.volume ? this.state.intvalue.volume : ""
                }
              />
            </FormControl>
          </Paper>
        </div>
        <div className={classes.volItem}>
          <InputLabel
            htmlFor="vol2"
            shrink={true}
            className="gm"
            style={{ color: "#000000" }}
          >
            <Trans i18nKey="pars">Pars</Trans>
          </InputLabel>

          <Paper square elevation={0} className={classes.volpaper}>
            <FormControl>
              <Input
                type="number"
                className={classes.intInputs}
                id="vol2"
                onChange={(e) => this.handleint(e)}
                name="paragraph"
                value={
                  this.state.intvalue.paragraph
                    ? this.state.intvalue.paragraph
                    : ""
                }
              />
            </FormControl>
          </Paper>
        </div>
        <div className={classes.volItem}>
          <InputLabel
            htmlFor="vol3"
            shrink={true}
            className="gm"
            style={{ color: "#000000" }}
          >
            <Trans i18nKey="page">Page</Trans>
          </InputLabel>
          <Paper square elevation={0} className={classes.volpaper}>
            <FormControl>
              <Input
                type="number"
                className={classes.intInputs}
                id="vol3"
                onChange={(e) => this.handleint(e)}
                name="page"
                value={this.state.intvalue.page ? this.state.intvalue.page : ""}
              />
            </FormControl>
          </Paper>
        </div>
        <div className={classes.volItem}>
          <InputLabel
            htmlFor="vol4"
            shrink={true}
            className="gm"
            style={{ color: "#000000" }}
          >
            <Trans i18nKey="line">Line</Trans>
          </InputLabel>
          <Paper square elevation={0} className={classes.volpaper}>
            <FormControl>
              <Input
                type="number"
                className={classes.intInputs}
                id="vol4"
                onChange={(e) => this.handleint(e)}
                name="line"
                value={this.state.intvalue.line ? this.state.intvalue.line : ""}
              />
            </FormControl>
          </Paper>
        </div>
      </div>
    );

    return (
      <div className="content">
        <form onSubmit={this.handleSubmit}>
          <div style={{ marginBottom: this.props.bottom ? "40px" : null }}>
            <div className={classes.rowContainer}>
              <Typography className={classes.rowName} align="center">
                {t("Row")} 1
              </Typography>
              {volSearchForm}
              <Paper square elevation={0} className={classes.searchpaper}>
                <InputLabel
                  htmlFor="field"
                  shrink={true}
                  className={classes.categorytag}
                  style={{ color: "#000000" }}
                >
                  {t("Category")}:
                </InputLabel>
                <Select
                  native
                  id="field"
                  name="field"
                  className={classes.categoryField}
                  value={this.state.temp.search[0].field}
                  fullWidth
                  onChange={(e) => this.inputHandler(e, 55)}
                >
                  {fvalue.map((el, index) => {
                    return (
                      <option value={el}>
                        {t(["fieldtype", fname[index]])}
                      </option>
                    );
                  })}
                </Select>
              </Paper>
              <Paper square elevation={0} className={classes.searchpaper}>
                <InputBase
                  fullWidth
                  className={classes.input}
                  name="value"
                  placeholder={t("Search Term")}
                  value={this.state.temp.search[0].value}
                  onChange={(e) => this.inputHandler(e, 55)}
                  inputProps={{
                    "aria-label": "search term",
                    autoCapitalize: "none",
                  }}
                />
              </Paper>
              {searchDetails.length < 1 ? addRowButton : null}
            </div>
            <SearchListMobile
              delete={this.clickOnDelete.bind(this)}
              searchDetails={searchDetails}
              variant={this.props.variant}
              handleChange={this.handleChange.bind(this)}
              close={this.props.close}
              addRowButton={addRowButton}
            />
          </div>
          <div className={classes.rowContainer}>
            <div
              className={
                this.props.bottom ? classes.bottom : "mobile-search-btn"
              }
              style={{
                marginTop: "0px",
              }}
            >
              <SearchButtonMobile close={this.props.close} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(
  withTranslation()(withStyles(useStyles)(AdvancedMobile))
);
