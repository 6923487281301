import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StandardSearch from "./SearchTab/StandardSearch";
import AdvancedSearch from "./SearchTab/AdvancedSearch";
import AlphabeticalSearch from "./SearchTab/AlphabeticalSearch";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, currentTabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {currentTabIndex === index && <> {children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  currentTabIndex: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 620,
  },
  searchAppbar: {
    backgroundColor: "transparent",
  },
  tab: {
    padding: "30px 0",
  },
}));

export default function SearchTabs({ variant, close, values }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  var bgcolor = "#ffffff";
  if (variant === "dark") {
    bgcolor = "#121212";
  } else {
    bgcolor = "#ffffff";
  }

  // console.log("SearchTab values", values);

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        width: "100%",
        minWidth: "193px",
        backgroundColor: `${bgcolor}`,
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      padding: "0!important",
      color: `${bgcolor}`,
      fontSize: "18px",
      fontFamily: "Gotham-Medium",
      lineHeight: "30px",
      "&:focus": {
        opacity: 1,
      },
      height: "60px",
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={0}
        color="inherit"
        className={classes.searchAppbar}
      >
        <StyledTabs
          value={currentTabIndex}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs"
        >
          <StyledTab label={t("Standard Search")} {...a11yProps(0)} />
          <StyledTab label={t("Advanced Search")} {...a11yProps(1)} />
          <StyledTab label={t("Alphabetical Search")} {...a11yProps(2)} />
        </StyledTabs>
      </AppBar>

      <TabPanel
        component="div"
        currentTabIndex={currentTabIndex}
        index={0}
        dir={theme.direction}
        className={classes.tab}
      >
        <StandardSearch close={close} values={values} />
      </TabPanel>
      <TabPanel
        component="div"
        currentTabIndex={currentTabIndex}
        index={1}
        dir={theme.direction}
        className={classes.tab}
      >
        <AdvancedSearch
          key="advanced-search"
          id="advanced-search"
          variant={variant}
          close={close}
          values={values}
        />
      </TabPanel>
      <TabPanel
        component="div"
        currentTabIndex={currentTabIndex}
        index={2}
        dir={theme.direction}
        className={classes.tab}
      >
        <AlphabeticalSearch close={close} values={values} />
      </TabPanel>
    </div>
  );
}
