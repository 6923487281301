import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Background from "../components/images/bg.jpg";
import { Container, Grid, Typography } from "@material-ui/core";
import SearchTabs from "../components/SearchTabs";
import MobileSearchTabs from "../components/MobileTabs/MobileSearchTabs";
// import Loader from "../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import Toast from "../components/Toast";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    minHeight: "100vh",
    width: "100%",
    marginTop: "-120px",
    display: "flex",
    alignItems: "center",
  },
  heroMobile: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    width: "100%",
    marginTop: "-70px",
    height: "300px",
    display: "flex",
    alignItems: "center",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignSelf: "flex-start",
    },
  },

  sectionMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  searchtabs: {
    margin: "120px auto !important",
    maxWidth: "620px !important",
    display: "block",
  },

  searchArea: {
    width: "100%",
  },
  mainTitle: {
    color: "#ffffff",
    marginBottom: "50px",
  },
  mainMobileTitle: {
    width: "100%",
    color: "#ffffff",
    textAlign: "center",
  },
  mobileSearchArea: {
    padding: "40px 0",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Homepage() {
  const { t, i18n } = useTranslation();
  let query = useQuery();
  let login = query.get("login");
  let logout = query.get("logout");

  const history = useHistory();

  useEffect(() => {
    checklogin();
    checkLogout();
  }, []);
  const [loginSnack, setLoginSnack] = React.useState(false);
  const [loginFailedSnack, setLoginFailedSnack] = React.useState(false);
  const [logoutSnack, setLogoutSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      // remove login=success from url
      history.replace("/");
      setLoginSnack(true);
    } else if (login === "fail") {
      // remove login=fail from url
      history.replace("/");
      setLoginFailedSnack(true);
    }
  };

  const checkLogout = () => {
    if (logout === "success") {
      setLogoutSnack(true);
      // remove logout=success from url
      history.replace("/");
    }
  };
  const classes = useStyles();

  return (
    <>
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      {loginFailedSnack ? (
        <Toast
          dialog={true}
          status={true}
          type="error"
          message="Login Failed"
        />
      ) : (
        ""
      )}
      {logoutSnack ? (
        <Toast status={true} type="success" message="Logged Out Successfully" />
      ) : (
        ""
      )}

      <div className="body-container">
        <Navbar />
        <div className={classes.sectionDesktop} key="desk">
          <div className={classes.hero}>
            <div className={classes.searchArea}>
              <div className={classes.searchtabs}>
                <Typography
                  variant="h1"
                  align="center"
                  className={classes.mainTitle}
                >
                  Thesaurus Linguae Latinae
                </Typography>
                <SearchTabs
                  id="search-tabs"
                  key="search-tabs"
                  close={(e) => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sectionMobile}>
          <div className={classes.heroMobile}>
            <Typography
              variant="h2"
              align="center"
              className={classes.mainMobileTitle}
            >
              Thesaurus Linguae Latinae
            </Typography>
          </div>
        </div>
        <div className={classes.sectionMobile} key="mob">
          <div className={classes.mobileSearchArea}>
            <MobileSearchTabs
              id="mobile-search-tabs"
              key="mobile-search-tabs"
              bottom={false}
              close={(e) => {}}
            />
          </div>
        </div>
        <div className="main">
          <section className="section section-y">
            <Container className="container">
              <Grid container spacing={2}>
                <Grid item md={3}></Grid>
                <Grid item sm={12} md={6}>
                  <Typography variant="h1" align="center">
                    <Trans i18nKey="about">About</Trans>
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                    style={{ marginTop: "20px" }}
                  >
                    {t("aboutfirst")}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                    style={{ marginTop: "20px" }}
                  >
                    <Trans i18nKey="aboutsecond">
                      The database currently contains lemmata from <i>a</i> to{" "}
                      <i>repressē</i> (Date: July 2022).
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
