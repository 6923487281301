import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import NavBarFixed from "../components/NavBarFixed";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Select,
  Button,
  MenuItem,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../components/Spinner/Spinner";
import axios from "axios";
import * as constants from "../utils/Constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import {
  fetchFileName,
  getSearchFiltersFromQuery,
  isJWTPresent,
  isNotNullOrUndefined,
  onValidateUserSuccessResponse,
} from "../utils/helper_functions";
import { useTranslation, Trans } from "react-i18next";
import Toast from "../components/Toast";
import usePrevious from "../utils/hooks/usePrevious";
import PayWall from "../components/Paywall";
import SearchValues from "../components/Results/SearchValues";
import { ReactSession } from "react-client-session";

const useStyles = makeStyles((theme) => ({
  toparea: {
    marginTop: "40px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
  findpaper: {
    width: "100%",
    padding: "2px 3px 2px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    border: "0.5px solid #A4A4A4",
    [theme.breakpoints.up("md")]: {
      minWidth: "355px",
      maxWidth: "355px",
    },
  },
  filterpaper: {
    width: "100%",
    padding: "12px 20px 13px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ffffff",
    border: "0.5px solid #A4A4A4",
    "& select": {
      fontSize: "16px",
      fontFamily: "Gotham-Book",
    },
  },
  icons: {
    borderLeft: "1px solid #dddddd",
    borderRadius: "0",
  },
  icon: {
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  alphabetBtn: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "25px",
  },
  abtn: {
    fontFamily: "'Gotham-Medium'",
    fontSize: "16px",

    minWidth: "33px",
    minHeight: "33px",
    maxWidth: "33px",
    maxHeight: "33px",
    border: "none!important",
  },
  btnContainer: {
    minWidth: "33px",
    minHeight: "33px",
    maxWidth: "33px",
    maxHeight: "33px",
    marginTop: "3px",
    backgroundColor: "#005d781a",
    "&:not(:last-child)": {
      marginRight: "3px",
    },
    "&:hover": {
      backgroundColor: "#007596!important",
      "& $abtn": {
        color: "#ffffff!important",
      },
    },
  },
  resultpaper: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    padding: "20px",
    marginBottom: "10px",
  },
  section: {
    paddingBottom: "90px",
  },
  textres: {
    fontSize: "18px",
    marginBottom: "20px",
    fontFamily: "Gotham-Medium",
    color: "#000000",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "30px",
    "& select": {
      fontSize: "16px",
      fontFamily: "Gotham-Book",
      height: "30px",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Gotham-Book",
      fontSize: "16px",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  bodycontent: {
    display: "-webkit-box",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      "-webkit-line-clamp": "1",
    },
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  input: {
    marginRight: "10px",
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Book","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
      opacity: "unset!important",
      textOverflow: "ellipsis",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  },

  sectionMobile: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  pagebtn: {
    "& button": {
      width: "40px !important",
      height: "40px !important",
    },
  },
  select: {
    width: "max-content !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "26px !important",
    color: "#121212 !important",
    marginLeft: "10px",
    "& .MuiSelect-select": {
      fontWeight: "500 !important",
      fontSize: "16px !important",
      lineHeight: "26px !important",
    },
    "& svg": {
      color: "#121212 !important",
      marginLeft: "10px",
    },
  },
  menuItem: {
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "26px !important",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
  resCountWrapper: {
    "& p": {
      display: "inline-block",
      width: "max-content",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      textAlign: "center",
    },
  },
  wrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      alignItems: "center",
      width: "100%",
    },
  },
  searchPara: {
    display: "flex",
    justifyContent: "space-between",
  },
  fadeOut: {
    position: "relative",
    boxShadow: "none !important",
    "&:after": {
      content: '""',
      height: "60%",
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(255,255,255,0.7), #f6f6f8)",
    },
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        height: "70%",
      },
    },
  },
  resultSearch: {
    // display: "flex",
    fontWeight: "900",
  },
}));

function Results(props) {
  const { t } = useTranslation();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const {
    pageNumber,
    prefixFilter,
    searchType,
    size,
    querySortBy,
    sortBy,
    sortType,
    searchForm,
    alphabetSearch,
    queryField,
    alphabetSearchField,
    volume,
    paragraph,
    line,
    page,
  } = getSearchFiltersFromQuery(query);
  var login = query.get("login");
  const [loginSnack, setLoginSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
    }
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [searchResults, setSearchResults] = React.useState([]);
  const [alphabetDisable, setAlphabetDisable] = React.useState(false);
  const [lastSearch, setLastSearch] = React.useState("");
  const [availableAlphabets, setAvailableAlphabets] = useState([]);
  const prevQuery = usePrevious(query);
  const uHistory = useHistory();

  // console.log("this is form", searchForm);

  const sortBySelectMenu = {
    relevance: t("relevance"),
    "lemma-asc": "Lemma A-Z",
    "lemma-desc": "Lemma Z-A",
    "pdate-asc": t("dateAscending"),
    "pdate-desc": t("dateDescending"),
  };

  const fieldValue = {
    LEMMA: "Lemma",
    FULL_TEXT: "Full Text",
    CITATION: "Citation",
    INDEX_LIBRORUM: "Index Librorum",
    AUTHOR_CITED: "Author Cited",
  };

  const sizeArray = [10, 20, 30];

  const navigateToValidate = () => {
    uHistory.replace({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };

  useEffect(() => {
    checklogin();
    setIsLoggedIn(isJWTPresent());
    const onlySearchIdChanged = onlyThisFieldChanged("searchId");
    const onlyPageNoChanged = onlyThisFieldChanged("pageNo");

    if (!(onlySearchIdChanged || onlyPageNoChanged)) {
      logTrackerEvent();
    }

    if (!onlySearchIdChanged) {
      loadData();
    }

    function onlyThisFieldChanged(field) {
      var onlyFieldChanged = true;
      if (prevQuery) {
        query.forEach(function (_, key) {
          if (key !== field && query.get(key) !== prevQuery.get(key)) {
            onlyFieldChanged = false;
          }
        });
        prevQuery.forEach(function (_, key) {
          if (key !== field && query.get(key) !== prevQuery.get(key)) {
            onlyFieldChanged = false;
          }
        });
      } else {
        onlyFieldChanged = false;
      }
      return onlyFieldChanged;
    }
  }, [query.toString()]);

  const loadData = () => {
    var jtoken = localStorage.getItem("jwtToken");

    const searchParams = new URLSearchParams();

    searchParams.set("size", size);
    searchParams.set("page", pageNumber);

    if (sortBy !== null) {
      searchParams.set("sortBy", sortBy);
      if (sortType === true) {
        searchParams.set("ascending", true);
      } else {
        searchParams.set("ascending", false);
      }
    }

    if (searchType === constants.SEARCH_TYPE.ALPHABETICAL_SEARCH) {
      if (alphabetSearch !== null || alphabetSearch !== undefined) {
        setLastSearch("alphabet");

        searchParams.set("prefix", alphabetSearch);
        searchParams.set("fieldType", alphabetSearchField);
        setAlphabetDisable(true);
        const urlType =
          queryField === constants.SEARCH_FIELDS.INDEX_LIBRORUM
            ? "index"
            : "article";

        const searchURL = new URL(
          `${process.env.REACT_APP_SERVER_HOST_NAME}/search/${urlType}/alphabetical`
        );
        searchURL.search = searchParams.toString();

        const headers = { Authorization: `Token ${jtoken}` };
        trackPromise(
          axios
            .get(searchURL, {
              headers,
            })
            .then((res1) => {
              const tempResults = res1.data;
              if (tempResults !== null && tempResults !== undefined) {
                setSearchResults(tempResults);
                setPageCount(Math.ceil((tempResults.totalHits ?? 0) / size));
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                navigateToValidate();
              }
              console.log("failed");
            })
        );
      }
    } else {
      setAlphabetDisable(false);
      setLastSearch("article");
      const headers = {
        Authorization: `Token ${jtoken}`,
      };

      const searchURL = new URL(
        `${process.env.REACT_APP_SERVER_HOST_NAME}/search/article/advance`
      );
      searchURL.search = searchParams.toString();
      trackPromise(
        axios
          .post(searchURL, searchForm, {
            headers,
          })
          .then((res1) => {
            const tempResults = res1.data;
            if (tempResults !== null && tempResults !== undefined) {
              setSearchResults(tempResults);
              setPageCount(Math.ceil((tempResults.totalHits ?? 0) / size));

              if (!query.get("searchId")) {
                const available = getAvailableAlphabets(res1.data);
                setAvailableAlphabets(available);
                const searchId = available.join("");
                if (searchId && searchId.length !== 0) {
                  uHistory.replace({
                    pathname: "/results",
                    search: query.toString() + `&searchId=${searchId}`,
                  });
                }
              } else {
                const available = query.get("searchId").split("");
                setAvailableAlphabets(available);
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 403) {
              navigateToValidate();
            }
            console.log("failed");
          })
      );
    }
  };

  const validateUser = () => {
    trackPromise(
      axios
        .post(constants.VALIDATE_USER_API, constants.getValidateUserAPIData())
        .then((response) => {
          setIsLoggedIn(response.data?.login === "success");

          if (isNotNullOrUndefined(response?.data?.jwtToken)) {
            onValidateUserSuccessResponse(response.data);
          }
        })
        .catch(() => console.log("failed"))
    );
  };

  const getAvailableAlphabets = (data) => {
    if (data?.aggregations?.asMap?.alphaFilter?.buckets) {
      const alphaList = data?.aggregations?.asMap?.alphaFilter?.buckets.map(
        (e) => e?.key
      );
      alphaList.sort();
      const set = new Set(alphaList);
      return Array.from(set).filter((e) => e !== "#");
    }
    return [];
  };

  const selectSize = (e) => {
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", "0");
    uParams.set("size", e.target.value.toString());
    uHistory.replace({
      pathname: "/results",
      search: uParams.toString(),
    });
  };

  const alphabetOptions = (s, e) => {
    let options = [];
    for (let i = s.charCodeAt(0); i <= e.charCodeAt(0); i++) {
      options.push(String.fromCharCode(i));
    }
    return options.map((e, idx) => {
      return (
        <option key={"op" + idx} className={classes.optionalpha} value={e}>
          {e}
        </option>
      );
    });
  };

  const selectSortby = (e) => {
    const value = e.target.value;
    window.scrollTo(0, 0);
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", "0");
    uParams.set("sortBy", value);
    uHistory.replace({
      pathname: "/results",
      search: uParams.toString(),
    });
  };
  const handlePageClick = (event, value) => {
    window.scrollTo(0, 0);
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", (value - 1).toString());
    uHistory.replace({
      pathname: "/results",
      search: uParams.toString(),
    });
  };
  const logTrackerEvent = () => {
    // const aid = localStorage.getItem("aid") ?? "1598265";
    const aid = localStorage.getItem("aid");
    if (!aid) {
      console.warn("aid not found");
      return;
    }
    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");

    script.type = "text/javascript";
    if (login !== "success") {
      script.innerHTML = `var _ll = _ll || [];
        _ll.push(["trackSearch", {
            "sid": '${ReactSession.get("sid")}',
            "aid": '${aid}',
            "aname": '${aname}',
            "url": '${window.location.href}',
            "sf": false
        }]);
        `;

      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    if (!isJWTPresent()) {
      validateUser();
    }
  }, []);

  const getHighlightFieldsList = (result) => {
    if (result.highlightFields) {
      const highlightFields = Object.values(result.highlightFields)?.flat();
      return highlightFields;
    }
    return [];
  };

  const getMetaContent = (result) => {
    const volume = result.volume;
    const pars = result.pars;
    const firstLine = result.firstLine;
    const firstPage = result.firstPage;
    const lastLine = result.lastLine;
    const lastPage = result.lastPage;
    const date = new Date(result.publishDate);
    const publishYear = date.getFullYear();
    const author = result.author;

    if (firstPage === lastPage && firstLine === lastLine) {
      return `TLL Vol. ${volume}, ${pars}, ${firstPage}, ${firstLine} (${author} ${publishYear})`;
    }
    return `TLL Vol. ${volume}, ${pars}, ${firstPage}, ${firstLine} - ${lastPage}, ${lastLine} (${author} ${publishYear})`;
  };

  const tiles = () => {
    const extraUrlParams = new URLSearchParams();

    extraUrlParams.set(
      "search_url",
      encodeURIComponent(window.location.pathname + window.location.search)
    );

    const articleDatapackageType = "Article";
    const indexDatapackageType = "Index";

    const offset = parseInt(pageNumber) * parseInt(size);

    var resultData = searchResults?.searchHits;
    if (isLoggedIn) {
      var reusltTile = resultData?.map((result, index) => {
        const packageTypeUrlParam = new URLSearchParams();

        packageTypeUrlParam.append("currentIndex", offset + index + 1);

        if (index !== 0) {
          const isPrevArticle =
            searchResults?.searchHits[index - 1]?.content?.lemma !== null &&
            searchResults?.searchHits[index - 1]?.content?.lemma !== undefined;
          const prevPackageType = isPrevArticle
            ? articleDatapackageType
            : indexDatapackageType;
          packageTypeUrlParam.append("prevPackageType", prevPackageType);
          packageTypeUrlParam.append(
            "prevPackageFileName",
            fetchFileName(searchResults.searchHits[index - 1].content.fileName)
          );
        }
        if (index !== searchResults.searchHits.length - 1) {
          const isNextArticle =
            searchResults?.searchHits[index + 1]?.content?.lemma !== null &&
            searchResults?.searchHits[index + 1]?.content?.lemma !== undefined;
          const nextPackageType = isNextArticle
            ? articleDatapackageType
            : indexDatapackageType;
          packageTypeUrlParam.append("nextPackageType", nextPackageType);
          packageTypeUrlParam.append(
            "nextPackageFileName",
            fetchFileName(searchResults.searchHits[index + 1].content.fileName)
          );
        }

        if (result.content.lemma != null) {
          let fileName = fetchFileName(result.content.fileName);

          const highlightFields = getHighlightFieldsList(result);

          const anyHighlights = highlightFields && highlightFields.length;

          return (
            <React.Fragment key={"fg" + index}>
              <Grid item md={1} xs={12}></Grid>

              <Grid item md={10} xs={12}>
                <Link
                  to={{
                    pathname: `/article/${fileName}`,
                    search:
                      packageTypeUrlParam.toString() +
                      "&" +
                      extraUrlParams.toString(),
                  }}
                  color="inherit"
                  prop="true"
                >
                  <Paper square elevation={0} className={classes.resultpaper}>
                    <Typography variant="h2" gutterBottom>
                      {result?.content?.lemmaText
                        ? result?.content?.lemmaText
                        : result?.content?.lemma[0] ?? ""}
                    </Typography>
                    {
                      // only show on development environment
                      process.env.NODE_ENV === "development" ? (
                        <Typography variant="h6" color="secondary" gutterBottom>
                          ElasticSearch score: {result?.score}
                        </Typography>
                      ) : null
                    }
                    <Typography variant="h6" gutterBottom>
                      {getMetaContent(result.content)}
                    </Typography>
                    {!anyHighlights ? (
                      <Typography
                        variant="h6"
                        className={classes.bodycontent}
                        gutterBottom
                        dangerouslySetInnerHTML={{
                          __html: result?.content?.searchBody
                            ? result?.content?.searchBody
                            : "",
                        }}
                      ></Typography>
                    ) : null}
                    {anyHighlights
                      ? highlightFields.map((highlight, hindex) => {
                          return (
                            <Typography
                              key={"h" + hindex}
                              variant="span"
                              className="highlight-body"
                              gutterBottom
                              dangerouslySetInnerHTML={{
                                __html: highlight ?? "",
                              }}
                            ></Typography>
                          );
                        })
                      : null}
                  </Paper>
                </Link>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
            </React.Fragment>
          );
        } else {
          let fileName = fetchFileName(result.content.fileName);
          return (
            <>
              <Grid item md={1} xs={12}></Grid>
              <Grid item md={10} xs={12}>
                <Paper square elevation={0} className={classes.resultpaper}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ marginBottom: "50px" }}
                  >
                    <Link
                      to={{
                        pathname: `/index/${fileName}`,
                        search:
                          packageTypeUrlParam.toString() +
                          "&" +
                          extraUrlParams.toString(),
                      }}
                      color="inherit"
                    >
                      {" "}
                      {result?.content?.title || ""}
                    </Link>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
            </>
          );
        }
      });
    } else {
      const tilePaperClasses = classes.resultpaper + " " + classes.fadeOut;
      let reusltTile = resultData?.slice(0, 1)?.map((result, index) => {
        if (
          result.content.lemma !== null &&
          result.content.lemma !== undefined
        ) {
          const highlightFields = getHighlightFieldsList(result);

          const anyHighlights = highlightFields && highlightFields.length;

          return (
            <React.Fragment key={"r-" + index}>
              <Grid item md={1} xs={12}></Grid>

              <Grid item md={10} xs={12}>
                <Paper square elevation={0} className={tilePaperClasses}>
                  <Typography variant="h2" gutterBottom>
                    {result?.content?.lemmaText
                      ? result?.content?.lemmaText
                      : result?.content?.lemma[0] ?? ""}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {getMetaContent(result.content)}
                  </Typography>
                  <Box height={50} />
                </Paper>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
            </React.Fragment>
          );
        } else {
          return (
            <>
              <Grid item md={1} xs={12}></Grid>
              <Grid item md={10} xs={12}>
                <Paper square elevation={0} className={tilePaperClasses}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ marginBottom: "50px" }}
                  >
                    {result?.content?.title ?? ""}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
            </>
          );
        }
      });
    }
    return reusltTile;
  };
  const addFilter = (e, val) => {
    alphaFilterUrlChange(val);
  };
  const changeAlphaFilter = (e) => {
    var val = e.target.value;
    alphaFilterUrlChange(val);
  };
  const alphaFilterUrlChange = (val) => {
    window.scrollTo(0, 0);
    const uParams = new URLSearchParams(query);
    uParams.set("pageNo", "0");
    if (prefixFilter === val) {
      uParams.delete("prefix");
    } else {
      uParams.set("prefix", val);
    }
    uHistory.replace({
      pathname: "/results",
      search: uParams.toString(),
    });
  };
  const classes = useStyles();
  const alphabetButtons = (s, e) => {
    let btns = [];
    for (let i = s.charCodeAt(0); i <= e.charCodeAt(0); i++) {
      btns.push(String.fromCharCode(i));
    }
    return btns.map((el) => {
      return (
        <div
          className={clsx(
            classes.btnContainer,
            prefixFilter === el ? " selectedbtn" : ""
          )}
        >
          <Button
            className={classes.abtn}
            value={el}
            onClick={(e) => addFilter(e, el)}
            close={props.close}
            disabled={
              !availableAlphabets.includes(el.toUpperCase()) &&
              !availableAlphabets.includes(el.toLowerCase())
            }
          >
            {el}
          </Button>
        </div>
      );
    });
  };

  const showPaywall = !isLoggedIn && searchResults?.totalHits;
  const isAlphabetSearch =
    alphabetSearch !== null &&
    alphabetSearch !== undefined &&
    lastSearch === "alphabet";

  return (
    <div className="body-container">
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      <NavBarFixed values={searchForm} prefix={alphabetSearch} bglight={true} />
      <div className="main">
        <section className={classes.section + " section"}>
          <Container className="container">
            <Grid container spacing={2} className="resultdata">
              <Grid item md={1}></Grid>
              <Grid
                item
                xs={12}
                md={10}
                className={classes.toparea + " " + classes.sectionDesktop}
              >
                <div>
                  <div className="results">
                    <Typography className={classes.resultSearch}>
                      {searchForm.search.map((e) => (
                        <SearchValues
                          searchFieldI18nKey={fieldValue[e?.field]}
                          searchField={fieldValue[e?.field]}
                          searchValue={e?.value}
                        />
                      ))}
                      {isAlphabetSearch && (
                        <SearchValues
                          searchFieldI18nKey={fieldValue[alphabetSearchField]}
                          searchField={fieldValue[alphabetSearchField]}
                          searchValue={alphabetSearch}
                        />
                      )}
                      <div className="d-flex">
                        {volume && (
                          <SearchValues
                            searchField="Vol"
                            searchValue={
                              volume + (paragraph || page || line ? "," : "")
                            }
                          />
                        )}
                        {paragraph && (
                          <SearchValues
                            searchField="Pars"
                            searchValue={paragraph + (page || line ? "," : "")}
                          />
                        )}
                        {page && (
                          <SearchValues
                            searchField="Page"
                            searchValue={page + (line ? "," : "")}
                          />
                        )}
                        {line && (
                          <SearchValues searchField="Line" searchValue={line} />
                        )}
                      </div>
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}></Grid>
              <Grid item md={10} xs={12}>
                <div className={classes.sectionDesktop}>
                  {!alphabetDisable ? (
                    <div className={classes.alphabetBtn}>
                      {alphabetButtons("A", "Z")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={classes.sectionMobile}
                  style={{ marginTop: "25px" }}
                >
                  {!alphabetDisable ? (
                    <Paper square elevation={0} className={classes.filterpaper}>
                      <Select
                        native
                        fullWidth
                        name="alphabetfilter"
                        onChange={changeAlphaFilter}
                        value={prefixFilter ? prefixFilter : ""}
                      >
                        <option value="">Filter A-Z</option>
                        {alphabetOptions("A", "Z")}
                      </Select>
                    </Paper>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item md={1}></Grid>

              <Grid item xs={12} md={1}></Grid>
              <Grid item xs={12} md={10}>
                <div className={classes.wrapper}>
                  <div className={classes.resCountWrapper}>
                    {searchResults?.totalHits !== undefined ? (
                      <Typography
                        variant="body2"
                        className={"gb " + classes.textres}
                      >
                        {searchResults?.totalHits ?? "0"}{" "}
                        <Trans i18nKey="results">results</Trans>
                      </Typography>
                    ) : (
                      <Typography>...</Typography>
                    )}
                  </div>
                  <div className={classes.sectionMobile}>
                    <Paper
                      square
                      elevation={0}
                      className={classes.filterpaper}
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="h6"
                        className="gb"
                        style={{
                          minWidth: "max-content",
                          marginRight: "10px",
                        }}
                      >
                        <Trans i18nKey="sortBy">Sort By</Trans>:
                      </Typography>
                      <Select
                        native
                        fullWidth
                        name="sortby"
                        onChange={selectSortby}
                        value={
                          querySortBy === null || querySortBy === undefined
                            ? "relevance"
                            : querySortBy
                        }
                      >
                        {Object.keys(sortBySelectMenu).map((key) => {
                          return (
                            <option value={key}>{sortBySelectMenu[key]}</option>
                          );
                        })}
                      </Select>
                    </Paper>
                  </div>
                  <div
                    className={classes.sectionDesktop}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div style={{ marginRight: "30px" }}>
                      <Typography
                        variant="body2"
                        className={"gb " + classes.textres}
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Trans i18nKey="itemsPerPage">Items per page</Trans>:
                        <Select
                          name="size"
                          value={size}
                          style={{ marginLeft: "10px" }}
                          onChange={selectSize}
                          className={classes.select + " gm"}
                        >
                          {sizeArray.map((sizeValue) => (
                            <MenuItem
                              value={sizeValue}
                              selected
                              classes={{ selected: classes.selected }}
                              className={classes.menuItem + " gm"}
                            >
                              {sizeValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        className={"gb " + classes.textres}
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Trans i18nKey="sortBy">Sort By</Trans>:
                        <Select
                          name="sortby"
                          onChange={selectSortby}
                          className={classes.select + " gm"}
                          value={
                            querySortBy === null || querySortBy === undefined
                              ? "relevance"
                              : querySortBy
                          }
                        >
                          {Object.keys(sortBySelectMenu).map((key) => {
                            return (
                              <MenuItem
                                value={key}
                                selected
                                classes={{ selected: classes.selected }}
                                className={classes.menuItem + " gm"}
                              >
                                {sortBySelectMenu[key]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
              <Spinner />
              {tiles()}
              <Grid item md={1} xs={12} />
              <Grid item md={10} xs={12}>
                {showPaywall ? (
                  <PayWall />
                ) : (
                  <div className={classes.pagination}>
                    {searchResults !== undefined ? (
                      <Pagination
                        className={classes.pagebtn}
                        shape="rounded"
                        count={pageCount}
                        page={pageNumber + 1}
                        onChange={handlePageClick}
                        color="primary"
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </Grid>
              <Grid item md={1} xs={12} />
            </Grid>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Results;
