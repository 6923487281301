import { ButtonGroup, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import useFindOnPage from "../utils/hooks/useFindOnPage";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  findpaper: {
    // width: "100%",
    padding: "2px 3px 2px 15px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    border: "0.5px solid #A4A4A4",
    "& .MuiInputBase-root": {
      flex: "1 !important",
    },
    "& button[hidden]": {
      display: "none !important",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px !important",
    },
  },
  icons: {
    borderRadius: "0",
  },
  icon: {
    minWidth: "unset !important",
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  stickyMobileFindOnPage: {
    // Changing this height also affect the scrollPaddingTop in GlobalStyles. Scrollinto view might hide behind it
    height: "55px !important",
    position: "fixed",
    left: "0%",
    top: "70px",
    zIndex: "100",
    background: "#E5DC07",
    border: "0",
    "& button": {
      background: "transparent !important",
    },
    "& svg": {
      color: "#121212 !important",
    },
    "& .MuiButtonGroup-root": {
      border: "0 !important",
    },
  },
  input: {
    border: "0",
    outline: "none",
    flexGrow: 2,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
}));

const FindOnPage = React.forwardRef(
  (
    { highlightClass, searchWithinClassName, showStickyOnMobile = false },
    ref
  ) => {
    const classes = useStyles();

    const [showBorder, setShowBorder] = useState(false);

    const { t } = useTranslation();

    const [showStickyMobileFindOnPage, setShowStickyMobileFindOnPage] =
      useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
      return () => {
        findString("");
      };
    }, []);

    const [findString, goToNext, gotToPrevious, currIndex, resultCount] =
      useFindOnPage(highlightClass, searchWithinClassName);

    // useImperativeHandle(ref, () => ({
    //   searchValue(newValue) {
    //     // console.log(inputRef.current);
    //     inputRef.current.value = newValue;
    //     handleFind(newValue);
    //   },
    // }));

    const onKeyPressSearch = (e) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        goToNext();
      }
    };

    const handleFind = (newValue) => {
      ReactGA.event({
        category: "User Actions",
        action: "Find on page",
        label: "Find on page",
        nonInteraction: false,
      });

      inputRef.current.value = newValue;
      if (newValue.length) {
        setShowBorder(true);
      } else {
        setShowBorder(false);
      }
      if (showStickyOnMobile) {
        setShowStickyMobileFindOnPage(newValue && newValue.length);
      }
      findString(newValue);
    };

    return (
      <Paper
        // onSubmit={(e) => handlesubmit(e)}
        square
        elevation={0}
        className={
          classes.findpaper +
          (showStickyMobileFindOnPage
            ? " " + classes.stickyMobileFindOnPage
            : "")
        }
        style={{
          border: showBorder ? "2px solid #E5DC07" : "1px solid #A4A4A4",
        }}
      >
        {/* <InputBase
          id="find"
          name="find"
          className={classes.input}
          onChange={(e) => handleFind(e.target.value)}
          placeholder={t("Find on page")}
          inputProps={{ "aria-label": "search term" }}
          onKeyPress={onKeyPressSearch}
          ref={inputRef}
          value={inputRef.current?.value}
        /> */}
        <DebounceInput
          minLength={2}
          debounceTimeout={300}
          onChange={(e) => {
            handleFind(e.target.value);
          }}
          id="find"
          name="find"
          className={classes.input}
          placeholder={t("Find on page")}
          // inputProps={{ "aria-label": "search term" }}
          onKeyPress={onKeyPressSearch}
          inputRef={inputRef}
        />
        <span className="number">
          {resultCount.current === 0 ? 0 : currIndex + 1}/{resultCount.current}
        </span>

        <ButtonGroup className={classes.icons}>
          <IconButton
            className={classes.icon}
            onClick={(_) => {
              gotToPrevious();
            }}
          >
            <svg
              width="13"
              height="7"
              viewBox="0 0 13 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 6L6.5 1L11.5 6"
                stroke="#3C3C3C"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>

          <IconButton
            className={classes.icon}
            onClick={(_) => {
              goToNext();
            }}
          >
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L6 6L1 0.999999"
                stroke="#3C3C3C"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>

          <IconButton
            hidden={!showStickyMobileFindOnPage}
            className={classes.icon}
            onClick={(e) => {
              setShowStickyMobileFindOnPage(false);
            }}
          >
            <svg
              width="22"
              height="25"
              viewBox="0 0 22 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.48047 6.82422L16.4409 18.4805"
                stroke="#121212"
                strokeWidth="1.5"
              />
              <path
                d="M16.4404 6.82422L5.48002 18.4805"
                stroke="#121212"
                strokeWidth="1.5"
              />
            </svg>
          </IconButton>
        </ButtonGroup>
      </Paper>
    );
  }
);

export default FindOnPage;

FindOnPage.propTypes = {
  highlightClass: PropTypes.string,
  searchWithinClassName: PropTypes.string,
  showStickyOnMobile: PropTypes.bool,
};
