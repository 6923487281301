import React, { useEffect, useImperativeHandle, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
  useMediaQuery,
  Tabs,
  Tab,
  AppBar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import commonStyles from "../../utils/commonStyles";
import * as constants from "../../utils/Constants";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  checkIfLanguageIs,
  minimizeXML,
  processSenseElements,
} from "../../utils/helper_functions";

import ReactGA from "react-ga4";

const TabPanel = React.forwardRef(function TabPanelComp(props, elementRef) {
  const { children, currentTabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <div ref={elementRef} hidden={currentTabIndex !== index}>
        {children}
      </div>
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  currentTabIndex: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  root: {
    backgroundColor: "transparent",
    width: "100%",
    "& h3": {
      color: "#121212",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      fontFamily: '"Merriweather","sans-serif" !important',
      marginBottom: "10px",
    },
    "& button": {
      fontFamily: '"Merriweather","sans-serif" !important',
      fontWeight: "700",
    },
  },
  searchAppbar: {
    backgroundColor: "transparent",
    zIndex: "998!important",
  },
  tab: {
    padding: "40px",
    backgroundColor: "#ffffff",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  },
  sectionMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  citecontainer: {
    width: "100%",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  accord: {
    paddingBottom: "20px",
    "&:not(:first-child)": {
      paddingTop: "20px",
    },
    maxWidth: "100%",
    minWidth: "calc(100vw - 50px)",
  },
  accordSummaryRoot: {
    padding: "0 !important",
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "none!important",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded span": {
      transform: "rotate(180deg)!important",
    },
    "& .MuiAccordionSummary-expandIcon": {
      color: "#000000",
      backgroundColor: "#ffffff !important",
      border: "0.5px solid #000000 !important",
      borderRadius: "0 !important",
      marginRight: "1px!important",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      backgroundColor: "#005d78!important",
      color: "#ffffff!important",
    },
  },
  accordExapnded: {},
  heading: {
    fontFamily: "'Merriweather'",
    fontSize: "20px",
    lineHeight: "30px",
  },
  details: {
    width: "100%",
    overflow: "hidden",
  },
  overx: {
    maxWidth: "calc(100vw - 60px)",
    marginTop: "20px",
    overflowX: "auto!important",
  },
  buttonGroup: {
    position: "fixed",
    bottom: "0px",
    right: "0px",
    width: "100%",
    height: "55px",
    zIndex: "1",
    display: "flex",
    "& > *": {
      flexGrow: "1",
    },
    "& button": {
      textTransform: "initial !important",
    },
  },
  mobileShowLineButton: {
    background: "#E5EFF1",
    border: "1px solid #005D78",
    color: "#005D78",
    "&:hover": {
      background: "#E5EFF1",
    },
  },
  mobileHideLineButton: {
    backgroundColor: "#005D78 !important",
    color: "white !important",
  },
  hidden: {
    display: "none",
  },
  hideGermanHeadings: {
    "& .german-heading": {
      display: "none",
    },
  },
  hideEnglishHeadings: {
    "& .english-heading": {
      display: "none",
    },
  },
  tocAnchor: {
    textDecoration: "underline !important",
  },
  refColumn: {
    "& a": {
      pointerEvents: "none",
      color: "inherit !important",
    },
  },
  quoteColumn: {
    pointerEvents: "none",
    "& a": {
      color: "inherit !important",
    },
  },
  hideEnglishToc: {
    "& .english-toc-heading": {
      display: "none",
    },
  },
  hideGermanToc: {
    "& .german-toc-heading": {
      display: "none",
    },
  },
  tocAccordionRoot: {
    background: "transparent",
    "&::before": {
      background: "none",
    },
  },
  tocAccordionDetailsRoot: {
    display: "block",
  },
  tocAccordionSummaryRoot: {
    padding: "0",
    justifyContent: "start",
    height: "auto",
    minHeight: "22px",
  },
  tocAccordionSummaryContent: {
    margin: "0px",
    flexGrow: "unset",
  },
  tocView: {
    "& .MuiIconButton-root": {
      color: "#121212 !important",
    },
  },
}));

const citation = (cit, classes) => {
  return (
    <div className={classes.citecontainer}>
      {/* <Typography className='gm' color='textPrimary' gutterBottom>
    Citations containing the {lemma}:
  </Typography> */}
      <table className="citTable" cellSpacing="0">
        <tbody>
          {cit?.map((el, index) => {
            return (
              <tr key={"citation" + index}>
                <td className="tll insidedata">
                  <a
                    className={classes.refColumn}
                    href={`#cite${el.citIndex}`}
                    dangerouslySetInnerHTML={{
                      __html: minimizeXML(el.refColumn) ?? "",
                    }}
                  ></a>
                </td>
                <td
                  className={"tll insidedata " + classes.quoteColumn}
                  dangerouslySetInnerHTML={{
                    __html: minimizeXML(el.quoteColumn) ?? "",
                  }}
                ></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const EntryTabs = React.forwardRef(
  ({ citdata, lemmadata, lemma, onTabChange }, entryTabsRef) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const [showLineNumbers, setShowLineNumbers] = useState(false);

    const findOnPageState = useSelector((state) => state.findOnPage);

    useImperativeHandle(entryTabsRef, () => ({
      changeTab(newTab) {
        // console.log("changing Tab", newTab);
        if (newTab !== undefined) {
          if (newTab === constants.EntryTabs.LEMMA_TAB) {
            setExpanded("panel1");
          } else {
            setExpanded("panel2");
          }
          setCurrentTabIndex(newTab);
          if (onTabChange) {
            onTabChange(newTab);
          }
        }
      },
    }));

    const handleChange = (event, newValue) => {
      // manually changing the tab
      setCurrentTabIndex(newValue);

      if (onTabChange) {
        onTabChange(newValue);
      }
    };
    const [expanded, setExpanded] = React.useState(false);

    const handlePanelChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      if (onTabChange) {
        onTabChange(
          panel === "panel2"
            ? constants.EntryTabs.CITE_TAB
            : constants.EntryTabs.LEMMA_TAB
        );
      }
      const el = document.getElementById("panel");
      el.scrollIntoView();
    };

    const mobileLineToggle = (event, value) => {
      ReactGA.event({
        category: "User actions",
        action: "Line Numbers Buton",
        label: value ? "Show" : "Hide",
        nonInteraction: false,
      });

      if (value !== null && value !== undefined) {
        setShowLineNumbers(value);
      }
    };

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

    const StyledTabs = withStyles({
      indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        top: 0,
        height: "5px",
        "& > span": {
          width: "100%",
          backgroundColor: "#005D78",
        },
      },
    })((props) => (
      <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
    ));

    const StyledTab = withStyles((theme) => ({
      root: {
        textTransform: "none",
        color: "#3c3c3c",
        fontFamily: "Merriweather",
        fontSize: "18px",
        "&:focus": {
          opacity: 1,
        },

        "&.Mui-selected": {
          backgroundColor: "#ffffff",
        },
        height: "60px",
      },
    }))((props) => <Tab disableRipple {...props} />);

    useEffect(() => {
      const anchors = document.querySelectorAll(".citTable td:first-child a");
      anchors?.forEach((e) => {
        e.onclick = () => {
          handleChange(null, constants.EntryTabs.LEMMA_TAB);
          setExpanded("panel1");
          if (onTabChange) {
            onTabChange(constants.EntryTabs.LEMMA_TAB);
          }
          setTimeout(() => {
            const target = document.getElementById(
              e?.getAttribute("href")?.substring(1)
            );
            target?.scrollIntoView({
              behavior: "smooth",
            });
          }, 500);
        };
      });
      if (expanded === "panel2") {
        setExpanded(false);
        setTimeout(() => setExpanded("panel2"), 100);
      }
    }, [citdata, currentTabIndex]);

    const lemmaXMLElementContainer = useRef(null);
    const lemmaXMLElementContainerMobile = useRef(null);

    useEffect(() => {
      const lineNumbers = document.querySelectorAll(".line-number");
      lineNumbers?.forEach((e) => {
        e.remove();
      });
      const originalXMLId = document.querySelectorAll(".original-xmlid");
      originalXMLId?.forEach((e) => {
        let id = e.getAttribute("xml:id");
        let locationList = [];
        if (id && id.length > 4) {
          id = id.substring(4);
          locationList = id.split(".");
          locationList = locationList.slice(2);
        }
        e.setAttribute("id", id);
        e.setAttribute("line-number", locationList.join("."));
      });
    }, [
      lemmadata,
      lemmaXMLElementContainer.current,
      lemmaXMLElementContainerMobile.current,
    ]);

    // useEffect(() => {
    //   if (lemmaXMLElementContainer.current !== null && lemmadata) {
    //     processSenseElements(lemmaXMLElementContainer.current);
    //   }
    // }, [lemmadata, lemmaXMLElementContainer.current]);

    // useEffect(() => {
    //   if (lemmaXMLElementContainerMobile.current !== null && lemmadata) {
    //     processSenseElements(lemmaXMLElementContainerMobile.current);
    //   }
    // }, [lemmadata, lemmaXMLElementContainerMobile.current]);

    useEffect(() => {
      if (!isDesktop) {
        const panel1 = document.getElementById("panel1");
        const panel2 = document.getElementById("panel2");

        const panel1MarkQuerySelectors = findOnPageState.onlyHighlightMark
          ? panel1?.querySelectorAll("mark")
          : panel1?.querySelectorAll(
              `mark.${constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS}`
            );

        const panel2MarkQuerySelectors = findOnPageState.onlyHighlightMark
          ? panel2?.querySelectorAll("mark")
          : panel2?.querySelectorAll(
              `mark.${constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS}`
            );

        const anyActiveMarkInPanel1 = panel1MarkQuerySelectors?.length;
        const anyActiveMarkInPanel2 = panel2MarkQuerySelectors?.length;

        if (anyActiveMarkInPanel1) {
          if (expanded !== "panel1") {
            setExpanded("panel1");
            if (onTabChange) {
              onTabChange(constants.EntryTabs.LEMMA_TAB);
            }
            setTimeout(function () {
              panel1MarkQuerySelectors[0]?.scrollIntoView();
            }, 1000);
          }
        } else if (anyActiveMarkInPanel2) {
          if (expanded !== "panel2") {
            setExpanded("panel2");
            if (onTabChange) {
              onTabChange(constants.EntryTabs.CITE_TAB);
            }
            setTimeout(function () {
              panel2MarkQuerySelectors[0]?.scrollIntoView();
            }, 1000);
          }
        }
      }
    }, [findOnPageState]);

    const hasCitations = citdata?.length > 0;

    return (
      <div
        className={
          classes.root +
          " resultdata " +
          (!checkIfLanguageIs("de")
            ? classes.hideGermanHeadings
            : classes.hideEnglishHeadings)
        }
      >
        <div className={classes.sectionDesktop}>
          <Hidden smDown>
            <AppBar
              position="static"
              elevation={0}
              color="inherit"
              className={classes.searchAppbar}
            >
              <StyledTabs
                className="searchIgnore"
                value={currentTabIndex}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs"
              >
                <StyledTab key="lemmakey" label="Lemma" {...a11yProps(0)} />
                {hasCitations ? (
                  <StyledTab
                    key="citationkey"
                    label={t("Citation")}
                    style={{ display: citdata ? "block" : "none" }}
                    {...a11yProps(1)}
                  />
                ) : (
                  <StyledTab label="" disabled />
                )}
                {/* <StyledTab label="PDF" {...a11yProps(2)} /> */}
              </StyledTabs>
            </AppBar>
            <TabPanel
              key="lemmapanel"
              component="div"
              currentTabIndex={currentTabIndex}
              index={0}
              dir={theme.direction}
              className={classes.tab + " entry-lemma lemma-search-div"}
              ref={lemmaXMLElementContainer}
            >
              {lemmadata}
            </TabPanel>
            {hasCitations && (
              <TabPanel
                key="citepanel"
                component="div"
                currentTabIndex={currentTabIndex}
                index={1}
                dir={theme.direction}
                className={classes.tab + " citdata cit-search-div"}
              >
                {currentTabIndex === 1 && citation(citdata, classes)}
              </TabPanel>
            )}
            {/* <TabPanel component='div' currentTabIndex={value} index={2} dir={theme.direction} className={classes.tab}>
          <Typography className='gm article-data' dangerouslySetInnerHTML={{ __html: articledata }} color='textPrimary'></Typography>
        </TabPanel> */}
          </Hidden>
        </div>
        <div className={classes.sectionMobile} id="panel">
          <Hidden mdUp>
            <Accordion
              // TransitionProps={{ timeout: "150ms" }}
              classes={{
                root: classes.accord,
              }}
              square
              elevation={0}
              expanded={expanded === "panel1"}
              onChange={handlePanelChange("panel1")}
              id="panel1"
            >
              <AccordionSummary
                className="searchIgnore"
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: classes.accordSummaryRoot }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>Lemma</Typography>
              </AccordionSummary>
              <AccordionDetails
                className={clsx(
                  classes.details,
                  showLineNumbers ? "showLineNumbers" : "hideLineNumbers"
                )}
              >
                <div
                  ref={lemmaXMLElementContainerMobile}
                  className={classes.overx + " lemma-search-div"}
                >
                  {lemmadata}
                </div>
                <ToggleButtonGroup
                  exclusive
                  onChange={mobileLineToggle}
                  value={showLineNumbers}
                  className={
                    "searchIgnore " +
                    (expanded === "panel1" ? "" : " " + classes.hidden)
                  }
                  classes={{ root: classes.buttonGroup }}
                >
                  <ToggleButton
                    value={true}
                    classes={{
                      selected: classes.mobileHideLineButton,
                      root: classes.mobileShowLineButton,
                    }}
                  >
                    <Trans i18nKey="showLines">Show lines</Trans>
                  </ToggleButton>
                  <ToggleButton
                    value={false}
                    classes={{
                      selected: classes.mobileHideLineButton,
                      root: classes.mobileShowLineButton,
                    }}
                  >
                    <Trans i18nKey="hideLines">Hide lines</Trans>
                  </ToggleButton>
                </ToggleButtonGroup>
              </AccordionDetails>
            </Accordion>
            {hasCitations && (
              <Accordion
                TransitionProps={{ timeout: "150ms" }}
                classes={{
                  root: classes.accord,
                }}
                square
                elevation={0}
                expanded={expanded === "panel2"}
                onChange={handlePanelChange("panel2")}
                id="panel2"
              >
                <AccordionSummary
                  className="searchIgnore"
                  classes={{ root: classes.accordSummaryRoot }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>
                    {t("Citation")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <div className={classes.overx + " citdata cit-search-div"}>
                    {citation(citdata, classes)}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </Hidden>
        </div>
      </div>
    );
  }
);

export default EntryTabs;
