import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, currentTabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {currentTabIndex === index && <> {children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  currentTabIndex: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
  },
  searchAppbar: {
    backgroundColor: "transparent",
    zIndex: "998!important",
  },
  tab: {
    padding: "40px",
    backgroundColor: "#ffffff",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  },
  sectionMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& .MuiAccordionSummary-expandIcon": {
      color: "#000000",
      backgroundColor: "#ffffff !important",
      border: "0.5px solid #000000 !important",
      borderRadius: "0 !important",
      marginRight: "1px!important",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      backgroundColor: "#005d78!important",
      color: "#ffffff!important",
    },
  },
  citecontainer: {
    width: "100%",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  accord: {
    paddingBottom: "20px",
    "&:not(:first-child)": {
      paddingTop: "20px",
    },
    maxWidth: "100%",
    minWidth: "calc(100vw - 50px)",
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "none!important",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded span": {
      transform: "rotate(180deg)!important",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0 !important",
    },
  },
  heading: {
    fontFamily: "'Gotham-Medium'",
    fontSize: "20px",
    lineHeight: "30px",
  },
  details: {
    width: "100%",
    overflow: "hidden",
  },
  overx: {
    maxWidth: "calc(100vw - 60px)",
    overflowX: "auto!important",
    marginTop: "20px",
  },
}));

function HelpTabs({ abbData, praemData, symbolsData }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleaChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    var el = document.getElementById("panel");
    el.scrollIntoView();
  };
  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      top: 0,
      height: "5px",
      "& > span": {
        width: "100%",
        backgroundColor: "#005D78",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      color: "#3c3c3c",
      fontFamily: "Gotham-Medium",
      fontSize: "18px",
      marginRight: theme.spacing(1),
      "&:focus": {
        opacity: 1,
      },

      "&.Mui-selected": {
        backgroundColor: "#ffffff",
      },
      height: "60px",
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <div className={classes.root + " resultdata"}>
      <div className={classes.sectionDesktop}>
        <Hidden smDown>
          <AppBar
            position="static"
            elevation={0}
            color="inherit"
            className={classes.searchAppbar}
          >
            <StyledTabs
              value={currentTabIndex}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="full width tabs"
            >
              <StyledTab
                key="lemmakey"
                label={t("praemonenda")}
                {...a11yProps(0)}
              />
              <StyledTab
                key="citationkey"
                label={t("Abbreviations")}
                {...a11yProps(1)}
              />
              <StyledTab
                key="citationkey"
                label={t("Symbols and Signs")}
                {...a11yProps(2)}
              />
              {/* <StyledTab label="PDF" {...a11yProps(2)} /> */}
            </StyledTabs>
          </AppBar>

          <TabPanel
            component="div"
            currentTabIndex={currentTabIndex}
            index={0}
            dir={theme.direction}
            className={classes.tab}
          >
            {praemData}
          </TabPanel>
          <TabPanel
            component="div"
            currentTabIndex={currentTabIndex}
            index={1}
            dir={theme.direction}
            className={classes.tab}
          >
            {abbData}
          </TabPanel>

          <TabPanel
            component="div"
            currentTabIndex={currentTabIndex}
            index={2}
            dir={theme.direction}
            className={classes.tab}
          >
            {symbolsData}
          </TabPanel>
        </Hidden>
      </div>
      <div className={classes.sectionMobile} id="panel">
        <Hidden mdUp>
          <Accordion
            TransitionProps={{ timeout: "150ms" }}
            classes={{
              root: classes.accord,
            }}
            square
            elevation={0}
            expanded={expanded === "panel1"}
            onChange={handleaChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {t("Abbreviations")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.overx}>{abbData}</div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            TransitionProps={{ timeout: "150ms" }}
            classes={{
              root: classes.accord,
            }}
            square
            elevation={0}
            expanded={expanded === "panel2"}
            onChange={handleaChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>
                {t("Praemonada")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.overx}>{praemData}</div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            TransitionProps={{ timeout: "150ms" }}
            classes={{
              root: classes.accord,
            }}
            square
            elevation={0}
            expanded={expanded === "panel3"}
            onChange={handleaChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>
                {t("Symbols and Signs")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.overx}>{symbolsData}</div>
            </AccordionDetails>
          </Accordion>
        </Hidden>
      </div>
    </div>
  );
}

export default HelpTabs;
