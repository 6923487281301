import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
        /* TODO: This is use for exact scroll padding on top. AppBar : 110px */
        // also for safari check the index.css
        scrollPaddingTop: "110px",
        [theme.breakpoints.down("sm")]: {
          /* TODO: appbar + finonpage 70px + 55px  */
          scrollPaddingTop: "125px",
        },
      },
      body: {
        backgroundColor: "#F6F6F8!important",
        height: "100%!important",
        width: "100%!important",
      },
      a: {
        textDecoration: "none!important",
        cursor: "pointer!important",
      },
      "#root": {
        height: "100%!important",
        width: "100%!important",
      },
      ".btn": {
        borderRadius: "0!important",
        textTransform: "capitalize!important",
        fontSize: "18px!important",
        lineHeight: "30px!important",
        padding: "13px 25px!important",
      },
      ".MuiInput-underline:before": {
        content: "none!important",
      },
      ".MuiInput-underline:after": {
        content: "none!important",
      },
      ".gm": {
        fontFamily: '"Gotham-Medium","sans-serif"!important',
      },
      ".gb": {
        fontFamily: '"Gotham-Book","sans-serif"!important',
      },
      ".times": {
        fontFamily: '"Times","serif"!important',
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
