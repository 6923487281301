export const EntryTabs = {
  LEMMA_TAB: 0,
  CITE_TAB: 1,
};

export const SEARCH_TYPE = {
  STANDARD_SEARCH: 0,
  ADVANCE_SEARCH: 1,
  ALPHABETICAL_SEARCH: 2,
};

export const SEARCH_FIELDS = {
  LEMMA: 0,
  FULL_TEXT: 1,
  CITATION: 2,
  INDEX_LIBRORUM: 3,
  AUTHOR_CITED: 4,
};

export const SEARCH_QUALIFIER = {
  AND: 0,
  OR: 1,
  NOT: 2,
};

export const CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS = "current";
export const SALES_URL = "https://www.degruyter.com/cms/pages/contacts?lang=en";

export const VALIDATE_USER_API =
  process.env.REACT_APP_SERVER_HOST_NAME + "/login/validateUser";

export const getValidateUserAPIData = () => {
  const data = {
    userAgent: window.navigator.userAgent,
    url: window.location.href,
    // ip: "86.120.23.240", // Degruyter trial account
    // ip: "82.137.7.187", // Vlad
  };

  return data;
};
