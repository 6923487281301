import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import letter from "./images/bg.jpg";
import { fetchWP } from "../utils/helper_functions";

const useStyles = makeStyles((theme) => ({
  loginImage: {
    top: "-6%",
    left: "9%",
    paddingTop: "50px",
    "& img": {
      width: "55%",
      "min-height": "430px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      left: "0%",
      top: "-20%",
      position: "static",
      padding: 0,
      "& img": {
        width: "100%",
        "min-height": "350px",
      },
    },
  },
  loginText: {
    position: "absolute",
    fontFamily: "times",
    width: "59%",
    top: "-10%",
    left: "41%",
    backgroundColor: "#ffffff",
    padding: "45px",
    zIndex: "1",
    boxShadow:
      " 0 6px 10px 0 rgba(0, 0, 0, 0.13), 0 -8px 20px 0 rgba(0, 0, 0, 0.13)",
    "& a": {
      color: "#005D78",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      display: "block",
      alignItems: "center",
      width: "100%",
      left: "0%",
      top: "0%",
      // position: "relative",
      padding: "25px",
    },
    [theme.breakpoints.up("md")]: {
      "min-height": "400px",
    },
  },
  parentContainer: {
    width: "100%",
    height: "51%",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      top: "12%",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  },
  blurTile: {
    position: "absolute",
    top: "384px",
    paddingTop: "145px",
    [theme.breakpoints.down("sm")]: {
      top: "21%",
      paddingTop: "303px",
    },
  },
  paywallText: {
    marginTop: "14px",
    fontWeight: "normal",
    fontFamily: "Times,serif",
    fontSize: "17px",
    lineHeight: "1.5",
    "& h1": {
      fontSize: "23px",
      fontWeight: "bold",
      fontFamily: "Gotham-Medium, tllmedium,sans-serif",
      marginBottom: "10px",
    },
    "& p": {
      marginBottom: "10px",
    },
  },
  getAccessText: {
    marginTop: "14px",
    fontWeight: "bold",
    fontSize: "23px",
    fontFamily: "Gotham-Medium, tllmedium,sans-serif",
  },
  innerDiv: {
    position: "relative",
    marginTop: "-50px",
    "min-height": "400px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-150px",
    },
  },
}));

const PayWall = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const redirectUrl = encodeURIComponent(
    window.location.pathname + window.location.search
  );

  const [message, setMessage] = useState("");

  useEffect(() => {
    const load = async () => {
      let paywallWP = await fetchWP("paywall", i18n.language);
      console.log(paywallWP);
      paywallWP = paywallWP.replaceAll(
        "/validate",
        `/validate?redirect_url=${redirectUrl}`
      );
      setMessage(paywallWP);
    };

    load();
  }, []);

  return (
    <div className={classes.parentContainer}>
      <div className={classes.innerDiv}>
        <div className={classes.loginText}>
          {/* <Typography className={classes.paywallText}>
            <Trans i18nKey="payWallLogin">
              Already have access?&nbsp;
              <a href={`/validate?redirect_url=${redirectUrl}`}>Login</a>
            </Trans>
          </Typography> */}
          <div
            className={classes.paywallText}
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
          {/* <Typography
            className={classes.paywallText + " " + classes.getAccessText}
          >
            <Trans i18nKey="payWallAccess">
              Get access to Thesaurus Linguae Latinae Online (TLL Online), the
              most comprehensive dictionary of ancient Latin.
            </Trans>
          </Typography>
          <Typography className={classes.paywallText}>
            <Trans i18nKey="payWallMail">
              An individual subscription to TLL Online costs €99 / $119 / £89
              (incl. VAT) per year. Please place your order via email to
              <a href="mailto:orders@degruyter.com">
                <u>orders@degruyter.com</u>
              </a>
              .
            </Trans>
          </Typography>
          <Typography className={classes.paywallText}>
            <Trans i18nKey="payWallSales">
              For further information and prices for institutional access,
              please contact our
              <a
                href="https://www.degruyter.com/cms/pages/contacts?lang=en"
                target="_blank"
              >
                <u>Sales Team</u>
              </a>
              .
            </Trans>
          </Typography> */}
        </div>

        <div className={classes.loginImage}>
          <img src={letter} alt="letter" />
        </div>
      </div>
    </div>
  );
};

export default PayWall;
