import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
const CustomDropDown = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={"select-box " + (props.className ? props.className : "")}>
      <div className="select-box__current" tabIndex="1">
        {props.optionsList?.map((e, index) => {
          return (
            <div key={"p" + index} className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id={props.name + "." + props.row + "." + index}
                // id={props.name}
                value={e}
                // name={props.name}
                name={props.name + "." + props.row + "." + index}
                onChange={(e) => {
                  // console.log("custom dropdown changed", e);
                  props.onChange(e);
                }}
                checked={e === props.defaultSelected}
              />
              <div className="select-box__input-text ">
                {/* {props.mobile && ( */}
                {/* <span className="select-title gm">{props.title}</span> */}
                {/* )} */}
                <p className="fw-500 select-value gm">
                  {t(props.nameList[index])}
                  <ArrowDropDownIcon />
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <ul className="select-box__list">
        {props.nameList?.map((e, index) => {
          return (
            <li key={"l" + props.row + index}>
              <label
                className="select-box__option fw-500 gm"
                htmlFor={props.name + "." + props.row + "." + index}
                // htmlFor={props.name}
                aria-hidden="aria-hidden"
              >
                {t(e)}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomDropDown;
