import React from "react";
import {
  makeStyles,
  Paper,
  InputBase,
  Typography,
  InputLabel,
  Select,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 37px 20px",
    maxWidth: "100%",
    "&:nth-child(even)": {
      backgroundColor: "#ffffff",
    },
  },

  searchpaper: {
    backgroundColor: "#ffffff",
    height: "55px!important",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #a4a4a4",
    marginBottom: "20px",
  },
  input: {
    paddingLeft: theme.spacing(4),
    "& .MuiInputBase-input::placeholder": {
      opacity: "unset !important",
      color: "#3c3c3c !important",
    },
  },
  category: {
    paddingLeft: theme.spacing(2),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  categorytag: {
    paddingLeft: "20px",
    lineHeight: "30px",
    fontFamily: '"Gotham-Book"',
    color: "#000",
  },
  rowdata: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    position: "relative",
  },
  rowTitle: {
    fontSize: "18px",
    fontFamily: '"Gotham-Medium"',
    lineHeight: "30px",
    width: "100%",
    marginBottom: "20px",
  },
  delete: {
    position: "absolute",
    right: 20,
    marginBottom: "0px",
    top: "2.5px",
    padding: "0",
  },
}));

const SearchList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const fvalue = ["LEMMA", "FULL_TEXT", "CITATION", "INDEX_LIBRORUM"];
  const fname = ["Lemma", "Full Text", "Citation", "Index Librorum"];
  const qvalue = ["AND", "OR", "NOT"];
  var bgcolor = "#3c3c3c";

  return props.searchDetails.map((val, idx) => {
    let content = "";
    if (val.index >= 0) {
      content = (
        <div className={classes.root} key={val.index}>
          <div className={classes.rowdata}>
            <Typography className={classes.rowTitle} align="center">
              {t("Row")} {idx + 2}
            </Typography>
            <IconButton
              className={classes.delete}
              disableRipple
              onClick={() => props.delete(val)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
                width="25"
                height="25"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    fill={bgcolor}
                    d="M22.4 5.46L22.4 6.35C22.4 6.48 22.36 6.59 22.27 6.67C22.19 6.75 22.08 6.79 21.95 6.79L20.61 6.79L20.61 20.02C20.61 20.79 20.39 21.46 19.96 22.02C19.52 22.58 18.99 22.87 18.38 22.87L6.77 22.87C6.16 22.87 5.63 22.59 5.2 22.05C4.76 21.51 4.54 20.85 4.54 20.08L4.54 6.79L3.2 6.79C3.07 6.79 2.96 6.75 2.88 6.67C2.8 6.59 2.75 6.48 2.75 6.35L2.75 5.46C2.75 5.33 2.8 5.22 2.88 5.13C2.96 5.05 3.07 5.01 3.2 5.01L7.51 5.01L8.49 2.68C8.63 2.34 8.88 2.04 9.24 1.8C9.6 1.56 9.97 1.44 10.34 1.44L14.81 1.44C15.18 1.44 15.55 1.56 15.91 1.8C16.27 2.04 16.52 2.34 16.66 2.68L17.64 5.01L21.95 5.01C22.08 5.01 22.19 5.05 22.27 5.13C22.36 5.22 22.4 5.33 22.4 5.46ZM18.83 20.02L18.83 6.79L6.33 6.79L6.33 20.02C6.33 20.22 6.36 20.41 6.42 20.59C6.49 20.76 6.56 20.88 6.63 20.96C6.7 21.04 6.74 21.08 6.77 21.08L18.38 21.08C18.41 21.08 18.46 21.04 18.53 20.96C18.6 20.88 18.66 20.76 18.73 20.59C18.79 20.41 18.83 20.22 18.83 20.02ZM17.04 9.92L17.04 17.96C17.04 18.09 17 18.19 16.91 18.28C16.83 18.36 16.72 18.4 16.59 18.4L15.7 18.4C15.57 18.4 15.46 18.36 15.38 18.28C15.3 18.19 15.25 18.09 15.25 17.96L15.25 9.92C15.25 9.79 15.3 9.68 15.38 9.6C15.46 9.52 15.57 9.47 15.7 9.47L16.59 9.47C16.72 9.47 16.83 9.52 16.91 9.6C17 9.68 17.04 9.79 17.04 9.92ZM9.9 9.92L9.9 17.96C9.9 18.09 9.86 18.19 9.77 18.28C9.69 18.36 9.58 18.4 9.45 18.4L8.56 18.4C8.43 18.4 8.32 18.36 8.24 18.28C8.15 18.19 8.11 18.09 8.11 17.96L8.11 9.92C8.11 9.79 8.15 9.68 8.24 9.6C8.32 9.52 8.43 9.47 8.56 9.47L9.45 9.47C9.58 9.47 9.69 9.52 9.77 9.6C9.86 9.68 9.9 9.79 9.9 9.92ZM9.45 5.01L15.7 5.01L15.03 3.38C14.97 3.29 14.89 3.24 14.79 3.22L10.37 3.22C10.28 3.24 10.2 3.29 10.13 3.38L9.45 5.01ZM13.47 9.92L13.47 17.96C13.47 18.09 13.43 18.19 13.34 18.28C13.26 18.36 13.15 18.4 13.02 18.4L12.13 18.4C12 18.4 11.89 18.36 11.81 18.28C11.72 18.19 11.68 18.09 11.68 17.96L11.68 9.92C11.68 9.79 11.72 9.68 11.81 9.6C11.89 9.52 12 9.47 12.13 9.47L13.02 9.47C13.15 9.47 13.26 9.52 13.34 9.6C13.43 9.68 13.47 9.79 13.47 9.92Z"
                  />
                </g>
              </svg>
            </IconButton>
          </div>
          <Paper square elevation={0} className={classes.searchpaper}>
            <InputLabel
              htmlFor={"qualifier" + idx}
              shrink={true}
              className={classes.categorytag}
              style={{ color: "#000000" }}
            >
              Operator:
            </InputLabel>
            <Select
              native
              id={"qualifier" + idx}
              value={val.searchQualifier}
              name={"searchQualifier" + idx}
              fullWidth
              onChange={(e) => props.handleChange(e, idx)}
            >
              {qvalue.map((el) => {
                return <option value={el}>{t(el)}</option>;
              })}
            </Select>
          </Paper>
          <Paper square elevation={0} className={classes.searchpaper}>
            <InputLabel
              htmlFor={"field" + idx}
              shrink={true}
              className={classes.categorytag}
              style={{ color: "#000000" }}
            >
              {t("Category")}:
            </InputLabel>

            {/* <CustomDropDownMobile id={"field"+idx} name='field' title='Category' row={idx} optionsList={fvalue}
                            onChange={(e) => props.handleChange(e, idx)}
                            nameList={fname}
                            defaultSelected={val.field}

                        >

                        </CustomDropDownMobile> */}
            <Select
              native
              id={"field" + idx}
              value={val.field}
              name={"field" + idx}
              fullWidth
              onChange={(e) => props.handleChange(e, idx)}
            >
              {fvalue.map((el, index) => {
                return <option value={el}>{t(fname[index])}</option>;
              })}
            </Select>
          </Paper>
          <Paper square elevation={0} className={classes.searchpaper}>
            <InputBase
              fullWidth
              className={classes.input}
              data-id={idx}
              onChange={(e) => props.handleChange(e, idx)}
              value={val.value}
              name="value"
              placeholder={t("Search Term")}
              inputProps={{
                "aria-label": "search term",
                autoCapitalize: "none",
              }}
            />
          </Paper>
          {idx === props.searchDetails.length - 1 ? props.addRowButton : null}
        </div>
      );
    }
    return content;
  });
};
export default SearchList;
