import React from "react";

const AutocompleteBox = ({
  inputRef,
  autoCompleteResults,
  setAutoCompleteResults,
  onSuggestionSelected,
  classes,
}) => {
  const autocompleteCurrentResultIndexRef = React.useRef(-1);
  const [autocompleteCurrentResultIndex, setAutocompleteCurrentResultIndex] =
    React.useState(-1);

  let isMountedRef = React.useRef(false);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    const delayedHideAutocompleteResults = () => {
      shouldCloseAutocompleteResults.current = true;
      // console.log("delayedHideAutocompleteResults");
      setTimeout(() => {
        if (!isMountedRef.current) return;
        // console.log("delayedHideAutocompleteResults Timeout");

        if (shouldCloseAutocompleteResults.current) {
          setAutoCompleteResults([]);
        }
      }, 200);
    };
    const handleKeyDown = (event) => {
      // up arrow
      if (event.keyCode === 38) {
        if (autocompleteCurrentResultIndexRef.current > -1) {
          autocompleteCurrentResultIndexRef.current -= 1;
          setAutocompleteCurrentResultIndex(
            autocompleteCurrentResultIndexRef.current
          );
          event.preventDefault();
        }
      }
      // down arrow
      else if (event.keyCode === 40) {
        if (
          autocompleteCurrentResultIndexRef.current <
          autoCompleteResults.length - 1
        ) {
          autocompleteCurrentResultIndexRef.current += 1;
          setAutocompleteCurrentResultIndex(
            autocompleteCurrentResultIndexRef.current
          );
          event.preventDefault();
        }
      }
      // enter
      else if (event.keyCode === 13) {
        if (autoCompleteResults.length > 0) {
          if (autocompleteCurrentResultIndexRef.current > -1) {
            onSuggestionSelected(
              autoCompleteResults[autocompleteCurrentResultIndexRef.current]
            );
            autocompleteCurrentResultIndexRef.current = -1;
            event.preventDefault();
          }
        }
      }
      // escape
      else if (event.keyCode === 27) {
        setAutoCompleteResults([]);
        autocompleteCurrentResultIndexRef.current = -1;
      }
    };

    // add onBlur event listener to input element
    // add OnFocus event listener to input element
    // add onKeyDown event listener to input element
    const focusInput = () => {
      shouldCloseAutocompleteResults.current = false;
      // console.log("focusInput");
    };

    const refCopy = inputRef.current;

    refCopy.addEventListener("blur", delayedHideAutocompleteResults);
    refCopy.addEventListener("focus", focusInput);
    refCopy.addEventListener("keydown", handleKeyDown);

    return () => {
      refCopy.removeEventListener("blur", delayedHideAutocompleteResults);
      refCopy.removeEventListener("focus", focusInput);
      refCopy.removeEventListener("keydown", handleKeyDown);
    };
  }, [autoCompleteResults, inputRef]);

  const shouldCloseAutocompleteResults = React.useRef(false);

  return (
    autoCompleteResults?.length > 0 && (
      <div className={classes.autocompleteResults}>
        {autoCompleteResults
          // .sort((a, b) => {
          //   // sort by score
          //   if (a.score > b.score) return -1;
          //   if (a.score < b.score) return 1;
          //   // if scores are equal, sort by lemmaText
          //   const aLemma = a.content.lemmaText.trim();
          //   const bLemma = b.content.lemmaText.trim();
          //   if (aLemma < bLemma) return -1;
          //   if (aLemma > bLemma) return 1;
          //   return 0;
          // })
          .map((result, resultIndex) => {
            const isSelected = autocompleteCurrentResultIndex === resultIndex;

            const title = isSelected
              ? result.content.lemmaText
              : result.content.lemma[0]?.replace(";", "");
            return (
              <div
                key={result.id}
                className={
                  classes.autocompleteResult +
                  (isSelected ? " " + classes.selected : "")
                }
                onMouseEnter={() => {
                  autocompleteCurrentResultIndexRef.current = resultIndex;
                  setAutocompleteCurrentResultIndex(resultIndex);
                }}
                onMouseDown={() =>
                  (shouldCloseAutocompleteResults.current = false)
                }
                onTouchStart={() =>
                  (shouldCloseAutocompleteResults.current = false)
                }
                onTouchEnd={() => onSuggestionSelected(result)}
                onClick={() => {
                  onSuggestionSelected(result);
                }}
              >
                <div className={classes.autocompleteResultTitle}>
                  {title}
                  {/* <small>{result.score}</small> */}
                </div>
              </div>
            );
          })}
      </div>
    )
  );
};

export default AutocompleteBox;
