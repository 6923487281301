import React from "react";
import {
  makeStyles,
  Paper,
  InputBase,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomDropDown from "./CustomDropDown";
import { useTranslation } from "react-i18next";
import { getSearchURL } from "../../utils/helper_functions";
import { SEARCH_FIELDS, SEARCH_TYPE } from "../../utils/Constants";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  searchpaper: {
    display: "flex",
    alignItems: "center",
    width: 620,
    border: "0.5px solid #a4a4a4",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 5,
  },
  category: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "19px",
    },
  },
  abtn: {
    fontFamily: "'Gotham-Medium'",
    fontSize: "14px",

    minWidth: "21.18px",
    minHeight: "21.18px",
    maxWidth: "21.18px",
    maxHeight: "21.18px",

    border: "none!important",
  },
  btnContainer: {
    padding: "6px 7px",
    "&:hover": {
      backgroundColor: "#007596!important",
      "& $abtn": {
        color: "#ffffff!important",
      },
    },
  },
  alphabetBtn: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 5,
  },
}));

const trackSearch = (event) => {
  // const
  console.log("search", event);
  const letter = event.currentTarget.value;

  ReactGA.event({
    category: "search",
    action: "Alphabetical Search",
    label: letter,
    nonInteraction: false,
  });
};

export default function AlphabeticalSearch(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [type, setType] = React.useState("LEMMA");
  let history = useHistory();

  const submitAlphabet = (e) => {
    trackSearch(e);

    props.close();
    const query = getSearchURL(
      SEARCH_TYPE.ALPHABETICAL_SEARCH,
      {},
      SEARCH_FIELDS[type],
      e.currentTarget.value
    );
    history.push({
      pathname: "/results",
      search: query,
    });
  };
  const alphabetButtons = (s, e) => {
    let btns = [];
    for (let i = s.charCodeAt(0); i <= e.charCodeAt(0); i++) {
      btns.push(String.fromCharCode(i));
    }
    return btns.map((e, idx) => {
      return (
        <div key={`bt${idx}`} className={classes.btnContainer}>
          <Button
            className={classes.abtn}
            value={e}
            close={props.close}
            onClick={submitAlphabet}
          >
            {e}
          </Button>
        </div>
      );
    });
  };
  const fvalue = ["LEMMA", "AUTHOR_CITED", "INDEX_LIBRORUM"];
  const fname = ["Lemma", "Author Cited", "Index Librorum"];
  const handleInput = (e) => {
    let value = e.currentTarget.value;
    setType(value);
  };
  return (
    <Paper square elevation={0} className={classes.searchpaper}>
      <CustomDropDown
        name="field"
        title={t("Type")}
        nameList={fname}
        optionsList={fvalue}
        onChange={handleInput}
        defaultSelected={type}
      ></CustomDropDown>
      <div className={classes.alphabetBtn}>{alphabetButtons("A", "Z")}</div>
    </Paper>
  );
}
