import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  searchField: {
    fontSize: "17px",
    fontWeight: "500",
    paddingRight: "5px",
    fontFamily: "Gotham-Book,tllmedium,sans-serif",
  },
  searchText: {
    fontSize: "17px",
    fontFamily: "Gotham-Book,tllmedium,sans-serif",
    fontWeight: "900",
    paddingLeft: "5px",
  },
  resultVal: {
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
    lineHeight: "40px",
  },
}));
const SearchValues = ({ searchField, searchValue, searchFieldI18nKey }) => {
  const classes = useStyles();
  return (
    <div className={classes.resultVal}>
      <Typography className={classes.searchField}>
        {searchFieldI18nKey ? (
          <Trans i18nKey={searchFieldI18nKey}>{searchField}</Trans>
        ) : (
          searchField
        )}{" "}
        :
      </Typography>

      <Typography className={classes.searchText}>{searchValue}</Typography>
    </div>
  );
};

SearchValues.propTypes = {
  searchField: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  searchFieldI18nKey: PropTypes.string,
};

export default SearchValues;
