import { createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#DDDDDD',
      default: '#f6f6f8',
      paper: '#f6f6f8'
    },
    primary: {
        dark: '#005D78',
      main: '#007596'
    },
    secondary: {
      main: '#FF001F'
    },
    text: {
      primary: '#121212',
      secondary: '#717173'
    }
  },
  spacing: 5,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Times',
      },
    },
  },
  typography: {
    fontFamily: [
      "Gotham-Book",
      'tllmedium',
      'Times',
      'Proxima Nova',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: [
        'Gotham-Medium',
      'tllmedium',
      'sans-serif'
      ].join(','),
      fontWeight: 500,
      fontSize: 30,
      color:'#121212',
      lineHeight:'40px',
    },
    h2: {
      fontFamily: [
        'Gotham-Medium',
      'tllmedium',
      'sans-serif'
      ].join(','),
      fontWeight: 500,
      color:'#121212',
      fontSize: 25,
      lineHeight:'35px',
    },
    h3: {
      fontFamily: [
        'Gotham-Medium',
      'tllmedium',
      'sans-serif'
      ].join(','),
      fontWeight: 500,
      fontSize: 30,
    },
    h4: {
      fontFamily: [
        'Gotham-Medium',
      'tllmedium',
      'sans-serif'
      ].join(','),
      fontWeight: 500,
      lineHeight:'30px',
      fontSize: 20,
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight:'30px',
    },
    h6: {
      fontFamily: [
        'Gotham-Medium',
      'tllmedium',
      'sans-serif'
      ].join(','),
      fontWeight: 500,
      lineHeight:'30px',
      fontSize: 16,     
    },
    overline: {
      fontWeight: 500
    },
    body2:{
      fontFamily: [
        'Gotham-Book',
        'sans-serif'
      ].join(','),
      fontSize:'12px',
    },  
    body1:{
      fontSize:'20px',
    }

  }
});

export default theme;
