import { Popover } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomPopOver = withStyles({
  paper: {
    background: "#005D78",
    borderRadius: "0",
    marginTop: "20px",
    overflow: 'visible',
    "&::before": {
      content: '""',
      position: "absolute",
      top: "-18px",
      left: "10px",
      borderBottom: "10px solid #005D78",
      borderRight: "10px solid transparent",
      borderLeft: "10px solid transparent",
      borderTop: "10px solid transparent",
      zIndex: 10,
    },
  },
})((props) => (
  <Popover
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  >
    {props.children}
  </Popover>
));

export default CustomPopOver;
