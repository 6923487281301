import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FindOnPage from "../components/FindOnPage";
import Footer from "../components/Footer";
import HelpTabs from "../components/HelpTabs";
import NavBarFixed from "../components/NavBarFixed";
import styles from "./pages.module.css";
import { fetchWP } from "../utils/helper_functions";
import { SpinnerForever } from "../components/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: "90px",
      paddingTop: "60px",
    },
  },
  title: {
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "55px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      marginBottom: "55px",
    },
  },
  coverarea: {
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    marginBottom: "20px",
  },
  videosection: {
    marginTop: "60px",
    marginBottom: "30px",
  },
  list: {
    padding: "20px 0 30px 15px",
    "& li": {
      fontFamily: '"Times"',
      fontSize: "20px",
      marginBottom: "15px",
    },
  },
  findOnPage: {
    marginTop: "25px",
    maxWidth: "355px",
    minWidth: "355px",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
}));
function Help() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const [praemData, setPraemonendaData] = useState("");
  const [abbData, setAbbreviationsData] = useState("");
  const [symbolsData, setSymbolsData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadWP = async () => {
      //load Praemonenda data
      const wpHTML = await fetchWP("praemonenda", i18n.language);
      if (!wpHTML) return;

      const a = (
        <div
          className={clsx(styles["container"], "appdata-data")}
          dangerouslySetInnerHTML={{ __html: wpHTML }}
        ></div>
      );
      setPraemonendaData(a);

      //load Abbreviations data
      const wpHTML2 = await fetchWP("abbreviations", i18n.language);
      if (!wpHTML2) return;

      const a2 = (
        <div>
          <div className={classes.findOnPage}>
            <FindOnPage
              highlightClass="current"
              searchWithinClassName=".wp-block-table"
            ></FindOnPage>
          </div>
          <br></br>
          <div
            className={clsx(styles["container"], "appdata-data")}
            dangerouslySetInnerHTML={{ __html: wpHTML2 }}
          ></div>
        </div>
      );
      setAbbreviationsData(a2);

      //load Symbols data
      const wpHTML3 = await fetchWP("symbols-and-signs", i18n.language);
      if (!wpHTML3) return;

      const a3 = (
        <div
          className={clsx(styles["container"], "appdata-data")}
          dangerouslySetInnerHTML={{ __html: wpHTML3 }}
        ></div>
      );
      setSymbolsData(a3);

      setIsLoading(false);
    };

    loadWP();
  }, [i18n.language, classes.findOnPage]);

  return (
    <div className="body-container">
      <NavBarFixed />
      <div className="main">
        <section className={classes.section + " section"}>
          <Container className="container">
            {!isLoading ? (
              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={8} className={classes.aboutarea}>
                  <Typography variant="h1" className={classes.title + " gm"}>
                    {t("helpUsingTLL")}
                  </Typography>
                  <HelpTabs
                    abbData={abbData}
                    praemData={praemData}
                    symbolsData={symbolsData}
                  />
                </Grid>

                <Grid item lg={8} xs={12}>
                  <Button
                    style={{ marginTop: "15px" }}
                    onClick={scrollToTop}
                    square
                    variant="outlined"
                    startIcon={
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.999999 5.8L5.84891 1M5.84891 1L10.6978 5.8M5.84891 1L5.84891 13"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  >
                    <Trans i18nKey="backToTop">Back To Top</Trans>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SpinnerForever></SpinnerForever>
              </div>
            )}
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Help;
