import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SEARCH_TYPE } from "../../utils/Constants";
import { getSearchURL } from "../../utils/helper_functions";
import { fetchFileName } from "../../utils/helper_functions";

import SearchListItem from "./SearchListItem";

import ReactGA from "react-ga4";

export default function StandardSearch(props) {
  const [formdata, setFormdata] = React.useState({
    search: [{ field: "LEMMA", value: "" }],
  });

  useEffect(() => {
    if (props?.values && props.values.search?.length) {
      const s = props.values.search[0];
      let newArr = { ...formdata }; // copying the old data array
      if (s.value) newArr.search[0].value = s.value;
      if (s.field) newArr.search[0].field = s.field;
      setFormdata(newArr);
    }
  }, []);

  const history = useHistory();
  const handleSubmit = (e) => {
    ReactGA.event({
      category: "search",
      action: "Standard Search",
      label: formdata.search[0].value,
    });

    if (e.preventDefault) e.preventDefault();
    const query = getSearchURL(SEARCH_TYPE.STANDARD_SEARCH, formdata);
    history.push({
      pathname: "/results",
      search: query,
    });
  };

  const handleInput = (e, id) => {
    let newArr = { ...formdata }; // copying the old data array

    let value = e.target.value;
    if (["value"].includes(e.target.name)) {
      let name = e.target.name;
      newArr.search[0][name] = value;
    } else if (["field"].includes(e.target.name)) {
      let name = "field";
      newArr.search[0][name] = value;
    }
    setFormdata(newArr);

    if (e.autocompleteSelected) {
      handleSubmit(e);
      if (props.close) props.close();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SearchListItem
        searchDetails={formdata.search}
        index={0}
        autoFocus={true}
        item={formdata.search[0]}
        handleChange={handleInput}
        close={props.close}
      ></SearchListItem>
    </form>
  );
}
