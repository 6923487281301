import {  SEARCH, NAVIGATION } from "../actions/findOnPageActions";

const initialState = {
  searchString: null,
  currentIndex: null,
  onlyHighlightMark: false,
};

const findOnPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return (state = action);
    case NAVIGATION:
      return (state = {...state, currentIndex: action.currentIndex});
    default:
      return state;
  }
};

export default findOnPageReducer;
