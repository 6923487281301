import React from "react";
import {
  withStyles,
  Paper,
  InputBase,
  FormControl,
  Input,
  InputLabel,
  Button,
} from "@material-ui/core";
import SearchButton from "./SearchButton";
import SearchListItem from "./SearchListItem";
import { useHistory, withRouter } from "react-router-dom";
import CustomDropDown from "./CustomDropDown";
import { Trans, useTranslation } from "react-i18next";
import { getSearchURL } from "../../utils/helper_functions";
import { SEARCH_TYPE } from "../../utils/Constants";
import SearchButtonMobile from "../MobileTabs/SearchButtonMobile";
import { makeStyles } from "@material-ui/styles";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  // ...commonStyles(theme),
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    // maxWidth: "45rem",
    boxSizing: "border-box",
    marginLeft: "1rem",
    marginRight: "1rem",
    // mobile media query
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
    },
  },
  volpaper: {
    padding: "14px 30px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
    border: "0.5px solid #a4a4a4",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  searchpaper: {
    display: "flex",
    alignItems: "center",
    // border: "0.5px solid #a4a4a4",
    // mobile media query
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-1rem",
      marginRight: "-1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",

      "&:nth-child(odd)": {
        backgroundColor: "#ffffff",
        paddingBottom: "1rem",
      },
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 2,
  },
  category: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "21px",
    },
  },
  formbtn: {
    "& button:hover": {
      backgroundColor: "#007596!important",
      color: "#ffffff!important",
      "& svg path": {
        stroke: "#ffffff",
      },
    },
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      color: "#121212!important",
      backgroundColor: "#ffffff",
      border: "0.5px solid #a4a4a4",
      margin: "0 10px 0px 0",
    },
  },
  intInputs: {
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Medium","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
    },
  },
}));

export default function AdvancedSearch(props) {
  // converting to functional component
  const [state, setState] = React.useState({
    searchDetails: [
      {
        index: 0,
        field: "FULL_TEXT",
        value: "",
      },
    ],
    intvalue: {},
  });

  const { t, i18n } = useTranslation();
  let { searchDetails } = state;
  const history = useHistory();

  const classes = useStyles();
  const bgcolor = props.variant === "dark" ? "#3c3c3c" : "#ffffff";

  // console.log("values", JSON.stringify(state.searchDetails, null, 2));

  React.useEffect(() => {
    let searchDetailsData = [];
    let intvaluesData = state.intvalue;

    let propdata = props?.values;
    if (props?.values !== undefined && props?.value !== null) {
      if (propdata.search && propdata.search.length) {
        propdata.search.forEach((el, i) => {
          let tempData = {};
          // tempData.index = i - 1;
          tempData.index = i;
          if (el.value !== undefined && el.value !== null) {
            tempData.value = el.value;
          }
          if (el.field !== undefined && el.field !== null) {
            tempData.field = el.field;
          }
          if (el.searchQualifier !== undefined && el.searchQualifier !== null) {
            tempData.searchQualifier = el.searchQualifier;
          }
          searchDetailsData.push(tempData);
        });
      }
      if (propdata?.volume !== undefined && propdata?.volume !== null) {
        intvaluesData.volume = propdata.volume;
      }
      if (propdata?.paragraph !== undefined && propdata?.paragraph !== null) {
        intvaluesData.paragraph = propdata.paragraph;
      }
      if (propdata?.line !== undefined && propdata?.line !== null) {
        intvaluesData.line = propdata.line;
      }
      if (propdata?.page !== undefined && propdata?.page !== null) {
        intvaluesData.page = propdata.page;
      }
      setState({
        searchDetails: searchDetailsData,
        intvalue: intvaluesData,
      });
    }
  }, []);

  const handleChange = (e, idx) => {
    // console.log("changing index " + idx);
    // console.log("e.targetName " + e.target.name);
    let newArr = [...state.searchDetails]; // copying the old data array
    if (["value"].includes(e.target.name)) {
      newArr[idx][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      setState(() => ({
        searchDetails: newArr,
        intvalue: state.intvalue,
      }));
      // } else if (["field" + idx].includes(e.target.name)) {
    } else if (["field"].includes(e.target.name)) {
      newArr[idx]["field"] = e.target.value; // replace e.target.value with whatever you want to change it to
      setState(() => ({
        searchDetails: newArr,
        intvalue: state.intvalue,
      }));
      // } else if (["searchQualifier" + idx].includes(e.target.name)) {
    } else if (["searchQualifier"].includes(e.target.name)) {
      newArr[idx]["searchQualifier"] = e.target.value; // replace e.target.value with whatever you want to change it to
      setState(() => ({
        searchDetails: newArr,
        intvalue: state.intvalue,
      }));
    } else {
      // console.log(state);
      // console.log(e.target.name);
      // console.log(e.target.value);
      // setState({ [e.target.name]: e.target.value });
    }
  };

  // const inputHandler = (e, id) => {
  //   let newArr = { ...state.searchDetails }; // copying the old data array
  //   if (["value"].includes(e.target.name)) {
  //     let name = e.target.name;
  //     let value = e.target.value;

  //     newArr.search[id][name] = value;
  //     setState(() => ({
  //       temp: newArr,
  //       intvalue: state.intvalue,
  //     }));
  //   } else if (["field" + id].includes(e.target.name)) {
  //     let name = "field";
  //     let value = e.target.value;

  //     newArr.search[0][name] = value;
  //     setState(() => ({
  //       temp: newArr,
  //       intvalue: state.intvalue,
  //     }));
  //   }
  // };

  const handleint = (e) => {
    if (["volume", "paragraph", "page", "line"].includes(e.target.name)) {
      let name = e.target.name;
      let value = e.target.value;

      let newArr = { ...state.intvalue }; // copying the old datas array
      // replace e.target.value with whatever you want to change it to
      newArr[name] = value;
      setState(() => ({
        searchDetails: state.searchDetails,
        intvalue: newArr,
      }));
    }
  };
  const addNewRow = (e) => {
    let i = 0;
    if (Array.isArray(state.searchDetails) && state.searchDetails.length) {
      i = parseInt(
        state.searchDetails[state.searchDetails.length - 1].index + 1
      );
    }
    const currentSearchDetails = state.searchDetails;
    setState((prevState) => ({
      intvalue: state.intvalue,
      searchDetails: [
        ...currentSearchDetails,
        {
          index: i,
          value: "",
          searchQualifier: "AND",
          field: "FULL_TEXT",
        },
      ],
    }));
  };

  const clearSearch = (e) => {
    setState({
      searchDetails: [
        {
          field: "FULL_TEXT",
          value: "",
        },
      ],
      intvalue: {},
    });
  };

  const trackSearch = (formdata) => {
    const searchFieldNames = formdata.search
      .map((s) => s.field)
      .sort((a, b) => a.localeCompare(b));

    const positionSearch = [];
    if (formdata.volume) {
      positionSearch.push("volume");
    }
    if (formdata.paragraph) {
      positionSearch.push("paragraph");
    }
    if (formdata.page) {
      positionSearch.push("page");
    }
    if (formdata.line) {
      positionSearch.push("line");
    }

    const combinedSearch = [...positionSearch, ...searchFieldNames].join(", ");

    // console.log("search", searchFieldNames);
    ReactGA.event({
      category: "search",
      action: "Advanced Search",
      label: combinedSearch,
    });
  };

  const handleSubmit = (e) => {
    // prevent default form submit behavior
    e.preventDefault();

    // create a copy of searchDetails to manipulate
    let sd = state.searchDetails;

    // remove index property from each search detail
    sd.forEach((el) => {
      delete el.index;
    });

    let formdata = { search: sd };

    // remove undefined values
    formdata.search = formdata.search
      .filter((element) => element !== undefined)
      .filter((element) => element.value?.trim());

    // check if volume is valid
    if (
      !isNaN(parseInt(state.intvalue.volume)) &&
      parseInt(state.intvalue.volume) !== undefined &&
      parseInt(state.intvalue.volume) !== null
    ) {
      // set volume to integer value
      formdata.volume = parseInt(state.intvalue.volume);
    }
    // check if paragraph is valid
    if (
      !isNaN(parseInt(state.intvalue.paragraph)) &&
      parseInt(state.intvalue.paragraph) !== undefined &&
      parseInt(state.intvalue.paragraph) !== null
    ) {
      // set paragraph to integer value
      formdata.paragraph = parseInt(state.intvalue.paragraph);
    }
    // check if page is valid
    if (
      !isNaN(parseInt(state.intvalue.page)) &&
      parseInt(state.intvalue.page) !== undefined &&
      parseInt(state.intvalue.page) !== null
    ) {
      // set page to integer value
      formdata.page = parseInt(state.intvalue.page);
    }
    // check if line is valid
    if (
      !isNaN(parseInt(state.intvalue.line)) &&
      parseInt(state.intvalue.line) !== undefined &&
      parseInt(state.intvalue.line) !== null
    ) {
      // set line to integer value
      formdata.line = parseInt(state.intvalue.line);
    }
    // check if only index is being searched for
    let indexflag = true;
    formdata.search.map((el) => {
      if (el.field !== "INDEX_LIBRORUM") {
        indexflag = false;
      }
      return indexflag;
    });

    trackSearch(formdata);
    // create query string from formdata
    const query = getSearchURL(SEARCH_TYPE.ADVANCE_SEARCH, formdata);
    // redirect to results page with query string
    history.push({
      pathname: "/results",
      search: query,
    });
  };
  // delete a search detail
  // const deleteRow = (index) => {
  //   // remove search detail at index
  //   setState({
  //     searchDetails: state.searchDetails.filter(
  //       (s, sindex) => index !== sindex
  //     ),
  //   });
  // };

  const clickOnDelete = (record) => {
    setState({
      searchDetails: state.searchDetails.filter((r) => r !== record),
      intvalue: state.intvalue,
    });
  };

  const addRowButton = (
    <Button
      className={classes.button + " addrow gm text-unset"}
      style={{ color: bgcolor }}
      onClick={addNewRow}
      startIcon={
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2913 6.0212L5.63449 6.0212L5.63449 0.364381"
            stroke={bgcolor}
            strokeWidth="1.5"
          />
          <path
            d="M-0.000276499 6.00028L5.65653 6.00028L5.65653 11.6571"
            stroke={bgcolor}
            strokeWidth="1.5"
          />
        </svg>
      }
    >
      {t("Add Row")}
    </Button>
  );

  const clearSearchButton = (
    <Button
      className={classes.button + " btn addrow gm"}
      style={{ color: bgcolor }}
      onClick={clearSearch}
      startIcon={
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.96872 9L4.96875 5.00003L8.96872 1.00006"
            stroke={bgcolor}
            strokeWidth="1.5"
          />
          <path
            d="M1.00003 1L5 4.99997L1.00003 8.99994"
            stroke={bgcolor}
            strokeWidth="1.5"
          />
        </svg>
      }
    >
      {t("Clear search")}
    </Button>
  );

  const volumeParsPageLine = (
    <Paper square elevation={0} className={classes.volpaper}>
      <FormControl>
        <InputLabel
          htmlFor="component-simple"
          shrink={true}
          className="gm"
          style={{ color: "#3C3C3C" }}
        >
          <Trans i18nKey="volume">Volume</Trans>
        </InputLabel>
        <Input
          type="number"
          className={classes.intInputs}
          id="component-simple"
          onChange={(e) => handleint(e)}
          autoComplete="off"
          name="volume"
          value={state.intvalue.volume ? state.intvalue.volume : ""}
          placeholder={t("Add")}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          htmlFor="component-simple"
          shrink={true}
          className="gm"
          style={{ color: "#3C3C3C" }}
        >
          <Trans i18nKey="pars">Pars</Trans>
        </InputLabel>
        <Input
          type="number"
          className={classes.intInputs}
          id="component-simple"
          onChange={(e) => handleint(e)}
          autoComplete="off"
          name="paragraph"
          value={state.intvalue.paragraph ? state.intvalue.paragraph : ""}
          placeholder={t("Add")}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          htmlFor="component-simple"
          shrink={true}
          className="gm"
          style={{ color: "#3C3C3C" }}
        >
          <Trans i18nKey="page">Page</Trans>
        </InputLabel>
        <Input
          type="number"
          className={classes.intInputs}
          id="component-simple"
          onChange={(e) => handleint(e)}
          autoComplete="off"
          name="page"
          value={state.intvalue.page ? state.intvalue.page : ""}
          placeholder={t("Add")}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          htmlFor="component-simple"
          shrink={true}
          className="gm"
          style={{ color: "#3C3C3C" }}
        >
          <Trans i18nKey="line">Line</Trans>
        </InputLabel>
        <Input
          type="number"
          className={classes.intInputs}
          id="component-simple"
          onChange={(e) => handleint(e)}
          autoComplete="off"
          name="line"
          value={state.intvalue.line ? state.intvalue.line : ""}
          placeholder={t("Add")}
        />
      </FormControl>
    </Paper>
  );

  return (
    <div>
      <form onSubmit={handleSubmit} className={classes.root}>
        {volumeParsPageLine}
        {searchDetails.map((item, idx) => (
          <div key={idx} className={classes.searchpaper}>
            <SearchListItem
              key={"s" + idx}
              mobile={props.mobile}
              searchDetails={searchDetails}
              index={idx}
              item={item}
              delete={clickOnDelete}
              variant={props.variant}
              handleChange={handleChange}
              close={props.close}
            />
          </div>
        ))}
        <div className={classes.formbtn}>
          {addRowButton}
          {clearSearchButton}
          {!props.mobile && searchDetails.length === 0 && (
            <SearchButton close={props.close} />
          )}
        </div>
        {props.mobile && <SearchButtonMobile close={props.close} />}
      </form>
    </div>
  );
}

// export default withRouter(
//   withTranslation()(withStyles(useStyles)(AdvancedSearch))
// );
