import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./images/DG_Logo.svg";
import MobileLogo from "./images/mobilelogodark.png";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  List,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Toast from "./Toast";
import {
  checkIfLanguageIs,
  doLogout,
  isJWTPresent,
} from "../utils/helper_functions";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 99,
    [theme.breakpoints.up("md")]: {
      top: "15px",
    },
  },
  appBar: {
    color: "#ffffff",
    backgroundColor: "transparent",
    padding: "0 5px",
    [theme.breakpoints.up("md")]: {
      padding: "0 90px",
    },
    "& .logomobile img": {
      height: "15px",
      width: "auto",
    },
  },

  toolbar: {
    minHeight: "70px !important",
  },
  appBarTransparent: {
    color: "#ffffff",
    "& .logomobile img": {
      filter: "invert()",
    },
    "& .mobileDrawerIcon svg path": {
      stroke: "#fff",
    },
    "& .user-icon svg circle": {
      stroke: "#ffffff",
    },
    "& .user-icon svg path": {
      fill: "#ffffff",
    },
    "& .user-login-icon svg circle": {
      stroke: "#007596",
      fill: "#007596",
    },
  },
  appBarWhite: {
    "& .logo img": {
      filter: "invert()",
    },
    color: "#000F1B",
    backgroundColor: theme.palette.background.default,
    "& .user-icon svg circle": {
      stroke: "#3c3c3c",
    },
    "& .user-icon svg path": {
      fill: "#3c3c3c",
    },
  },
  logo: {
    "& img": {
      width: "32px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  sectionMobile: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navlinks: {
    display: "flex",
    alignItems: "center",
  },
  navbtn: {
    marginLeft: "25px",
    "& button": {
      padding: "0px !important",
    },
  },
  navitem: {
    marginLeft: "25px",
  },
  navitemLanguage: {
    color: "white",
  },

  icon: {
    margin: 0,
    padding: 0,
  },

  mainTitle: {
    color: "#ffffff",
    marginBottom: "50px",
  },
  list: {
    backgroundColor: "#f6f6f8",
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#e1e1e1",
    display: "block",
  },
  whitelinks: {
    paddingBottom: "0!important",
    paddingTop: "0!important",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  menuItemMobile: {
    fontFamily: "'Gotham-Medium'!important",
    fontSize: "17px!important",
    lineHeight: "20px",
    color: "#000000",
    "& .MuiListItemText-primary": {
      fontFamily: "'Gotham-Medium'!important",
      fontSize: "17px!important",
      lineHeight: "20px",
      color: "#000000",
    },
  },
  menuIconMobile: {
    minWidth: "32px!important",
  },
  menubtn: {
    marginBottom: "20px",
    marginTop: "27px",
    paddingLeft: "24px",
    "& span": {
      fontSize: "17px",
      fontFamily: '"Gotham-Medium"',
    },
  },
  buttons: {
    marginRight: "10px",
    height: "55px",
    width: "130px",
  },
  closedrawer: {
    position: "fixed",
    right: "0",
    top: "0",
    color: "#ffffff",
  },
  profilemenu: {
    marginTop: "70px",
    "&  .MuiPopover-paper": {
      overflow: "visible!important",
    },
    "&  .MuiPopover-paper:before": {
      content: '" "',
      width: "0",
      height: "0",
      position: "absolute",
      borderLeft: "30px solid transparent",
      borderRight: "30px solid transparent",
      borderBottom: "40px solid #ffffff",
      zIndex: "-1",
      left: "50%",
      transform: "translate(-50%,-50%)",
      marginTop: "15px",
    },
  },
  menuListItems: {
    height: "60px",
  },
}));
function Navbar() {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [drawerMenu, setDrawerMenu] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerMenu(!drawerMenu);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [showSearch, setShowSearch] = React.useState(true);
  const changeVisibility = () => {
    var el = window.innerWidth < 600;

    if (window.scrollY >= 50 && el) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };
  window.addEventListener("scroll", changeVisibility);
  const handleLanguageChange = (lang) => {
    ReactGA.event({
      category: "Language",
      action: "Language Change",
      label: lang,
    });

    i18n.changeLanguage(lang);
  };

  const redirectUrl = encodeURIComponent(
    window.location.pathname + window.location.search
  );
  const validateUrl = `/validate?redirect_url=${redirectUrl}`;

  const renderDrawer = (
    <Drawer anchor="left" open={drawerMenu} onClose={toggleDrawer}>
      <div className={classes.list}>
        <div className={classes.closedrawer}>
          <IconButton onClick={toggleDrawer}>
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6055 10.6094L31.8187 31.8226"
                stroke="white"
                strokeWidth="2"
              />
              <path
                d="M31.8203 10.6094L10.6071 31.8226"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </IconButton>
        </div>

        {localStorage.getItem("jwtToken") === undefined ||
        localStorage.getItem("jwtToken") === null ? (
          <List disablePadding>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={t("Sign up")}
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={t("Sign in")}
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
            <Link to={validateUrl}>
              <ListItem className={classes.menuListItems}>
                <ListItemIcon className={classes.menuIconMobile}>
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                      fill="black"
                    />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Sign in via Institution"
                  className={classes.menuItemMobile}
                />
              </ListItem>
            </Link>
          </List>
        ) : (
          <List disablePadding>
            <ListItem
              onClick={(e) => {
                logout();
                handleMenuClose();
                toggleDrawer();
              }}
              style={{ cursor: "pointer" }}
              className={classes.menuListItems}
            >
              <ListItemIcon className={classes.menuIconMobile}>
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8028 11.6157C14.5935 10.4988 15.8246 8.54426 15.8246 6.25462C15.7686 2.79224 12.9707 0 9.50132 0C5.97597 0 3.17807 2.79224 3.17807 6.31047C3.17807 8.60011 4.40915 10.5547 6.1998 11.6716C2.73041 12.956 0.324219 16.2509 0.324219 20.16H2.00296C2.00296 18.0937 2.84233 16.2509 4.18532 14.9106C5.52831 13.5703 7.43088 12.7326 9.44536 12.7326C11.5158 12.7326 13.3624 13.5703 14.7054 14.9106C16.0484 16.2509 16.8878 18.1496 16.8878 20.16H18.5665C18.6225 16.2509 16.2163 12.956 12.8028 11.6157ZM4.85681 6.31047C4.85681 5.02604 5.36043 3.8533 6.1998 3.01562C7.03917 2.17795 8.21429 1.67535 9.50132 1.67535C10.7884 1.67535 11.9075 2.17795 12.7469 3.01562C13.5863 3.8533 14.0899 5.02604 14.0899 6.25462C14.0899 7.53906 13.5863 8.65595 12.7469 9.49363C11.9075 10.3313 10.7324 10.8339 9.50132 10.8339C8.21429 10.8339 7.09513 10.3313 6.25576 9.49363C5.36043 8.7118 4.85681 7.53906 4.85681 6.31047Z"
                    fill="black"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={t("Sign out")}
                className={classes.menuItemMobile}
              />
            </ListItem>
          </List>
        )}
        <List className={classes.whitelinks} disablePadding>
          {localStorage.getItem("user_name") !== undefined &&
          localStorage.getItem("user_name") !== null ? (
            <Link to="#">
              <ListItem
                className={classes.menuListItems}
                style={{
                  borderBottom: "0.5px solid #717173",
                  borderTop: "0.5px solid #717173",
                }}
              >
                <Typography variant="h4" className="gm">
                  {localStorage.getItem("user_name")}
                </Typography>
              </ListItem>
            </Link>
          ) : (
            ""
          )}
          <Link to="/about">
            <ListItem
              className={classes.menuListItems}
              style={{ borderBottom: "0.5px solid #717173" }}
            >
              <Typography variant="h4" className="gm">
                <Trans i18nKey="about">About</Trans>
              </Typography>
            </ListItem>
          </Link>
          <Link to="/help">
            {" "}
            <ListItem
              className={classes.menuListItems}
              style={{ borderBottom: "0.5px solid #717173" }}
            >
              <Typography variant="h4" className="gm">
                <Trans i18nKey="help">Help</Trans>
              </Typography>
            </ListItem>
          </Link>
        </List>
        <div className={classes.menubtn}>
          {/* // disableElevation */}
          <Button
            variant="contained"
            className={classes.buttons}
            color={checkIfLanguageIs("de") ? "primary" : "inherit"}
            onClick={() => handleLanguageChange("de")}
          >
            Deutsch
          </Button>
          {/* // disableElevation */}
          <Button
            variant="contained"
            className={classes.buttons}
            color={
              !checkIfLanguageIs("de") || i18n.language === undefined
                ? "primary"
                : "inherit"
            }
            onClick={() => handleLanguageChange("en")}
          >
            English
          </Button>
        </div>
        <List className={classes.bottomlinks} disablePadding>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="contact">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/contacts?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Contact
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="privacy">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/privacy-policy?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="termsCondition">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/terms-and-conditions?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Terms &amp; Conditions
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              <Trans i18nKey="imprint">
                <a
                  href={
                    "https://www.degruyter.com/cms/pages/imprint?lang=" +
                    (checkIfLanguageIs("de") ? "de" : "en")
                  }
                  target="_blank"
                >
                  Imprint
                </a>
              </Trans>
            </Typography>
          </ListItem>
          <span className={classes.divider}></span>
          <ListItem className={classes.menuListItems}>
            <Typography
              variant="body1"
              className={classes.menuItemMobile + " gm"}
            >
              © 2020 De Gruyter
            </Typography>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
  const [snack, setSnack] = React.useState(false);

  const logout = async () => {
    await doLogout();
    setSnack(true);
  };
  const menuId = "primary-search-account-menu";

  const renderMenuItem = () => {
    if (isJWTPresent()) {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          disableAutoFocus
          transitionDuration={0}
          TransitionProps={{ timeout: 0 }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} className="gm fs-16">
            {localStorage.getItem("user_name") !== undefined &&
            localStorage.getItem("user_name") !== null
              ? localStorage.getItem("user_name")
              : ""}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              logout();
              handleMenuClose();
            }}
            className="gm fs-16"
          >
            {" "}
            {t("Sign out")}
          </MenuItem>
        </Menu>
      );
    } else {
      return (
        <Menu
          className={classes.profilemenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          transitionDuration={0}
          TransitionProps={{ timeout: 0 }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} className="gm fs-16">
            <Link to={validateUrl}>
              {t("Sign in")} / {t("Sign up")}
            </Link>
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose} className="gm fs-16">
            <Link
              to={
                "/start-session?entityID=https%3A%2F%2Fidp.degruyter.com%2Fopenathens&target=" +
                encodeURIComponent(window.location.href)
              }
            >
              {t("Login with WAYFless")}
            </Link>
          </MenuItem> */}
        </Menu>
      );
    }
  };

  return (
    <div className={classes.grow}>
      {snack ? <Toast status={true} type="success" message="Logged Out" /> : ""}
      <AppBar
        elevation={0}
        position="static"
        className={clsx(
          classes.appBar,
          showSearch ? classes.appBarTransparent : classes.appBarWhite
        )}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionDesktop}>
            <NavLink className={classes.logo + " logo"} to="/">
              <img src={Logo} alt="website logo" />
            </NavLink>
            <div className={classes.navlinks}>
              <div className={classes.navitem}>
                <Link to="/about">
                  <Typography variant="h6" className={classes.title}>
                    <Trans i18nKey="about">About</Trans>
                  </Typography>
                </Link>
              </div>
              <div className={classes.navitem}>
                <Link to="/help">
                  <Typography variant="h6" className={classes.title}>
                    <Trans i18nKey="help">Help</Trans>
                  </Typography>
                </Link>
              </div>
              <div className={classes.navbtn}>
                {localStorage.getItem("user_name") !== undefined &&
                localStorage.getItem("user_name") !== null ? (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="primary"
                    className="user-login-icon"
                  >
                    <svg
                      width="45"
                      height="45"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="22.5"
                        cy="22.5"
                        r="21.75"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                        fill="white"
                      />
                    </svg>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="primary"
                    className="user-icon"
                  >
                    <svg
                      width="45"
                      height="45"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="22.5"
                        cy="22.5"
                        r="21.75"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M25.6038 23.9474C27.2689 22.8947 28.4137 21.0526 28.4137 18.8947C28.3617 15.6316 25.7599 13 22.5337 13C19.2555 13 16.6538 15.6316 16.6538 18.9474C16.6538 21.1053 17.7986 22.9474 19.4637 24C16.2375 25.2105 14 28.3158 14 32H15.561C15.561 30.0526 16.3416 28.3158 17.5904 27.0526C18.8393 25.7895 20.6084 25 22.4817 25C24.407 25 26.1241 25.7895 27.373 27.0526C28.6218 28.3158 29.4024 30.1053 29.4024 32H30.9634C31.0154 28.3158 28.7779 25.2105 25.6038 23.9474ZM18.2148 18.9474C18.2148 17.7368 18.6831 16.6316 19.4637 15.8421C20.2442 15.0526 21.3369 14.5789 22.5337 14.5789C23.7305 14.5789 24.7712 15.0526 25.5518 15.8421C26.3323 16.6316 26.8006 17.7368 26.8006 18.8947C26.8006 20.1053 26.3323 21.1579 25.5518 21.9474C24.7712 22.7368 23.6785 23.2105 22.5337 23.2105C21.3369 23.2105 20.2962 22.7368 19.5157 21.9474C18.6831 21.2105 18.2148 20.1053 18.2148 18.9474Z"
                        fill="white"
                      />
                    </svg>
                  </IconButton>
                )}
              </div>
              <div className={classes.navbtn}>
                <Button
                  onClick={() =>
                    handleLanguageChange(checkIfLanguageIs("de") ? "en" : "de")
                  }
                >
                  <Typography
                    variant="h6"
                    className={`${classes.title} ${classes.navitemLanguage}`}
                  >
                    {checkIfLanguageIs("de") ? "English" : "Deutsch"}
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <NavLink className={classes.logo + " logomobile"} to="/">
              <img src={MobileLogo} alt="website logo" />
            </NavLink>
            {!drawerMenu ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                className="mobileDrawerIcon"
              >
                <svg
                  width="25"
                  height="19"
                  viewBox="0 0 25 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1H25" stroke="black" strokeWidth="2" />
                  <path d="M0 9.5H25" stroke="black" strokeWidth="2" />
                  <path d="M0 18H25" stroke="black" strokeWidth="2" />
                </svg>
              </IconButton>
            ) : null}
          </div>

          {renderDrawer}
        </Toolbar>
      </AppBar>
      {renderMenuItem()}
    </div>
  );
}

export default Navbar;
