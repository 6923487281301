import axios from "axios";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import * as constants from "../utils/Constants";
import { fetchFileName } from "../utils/helper_functions";

const Location = (props) => {
  const location = props.match?.params?.location;
  const history = useHistory();

  useEffect(() => {
    const getFileName = () => {
      const token = localStorage.getItem("jwtToken");
      trackPromise(
        axios
          .get(
            process.env.REACT_APP_SERVER_HOST_NAME + `/rest/article/location/${location}`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              history.replace(`/article/${fetchFileName(response.data)}#${location}`);
            }
          })
          .catch((e) => console.log(e))
      );
    };

    getFileName();
  }, [location]);

  return (
    <div>
      <div className="center-container">
        <h1 className="gm"> Redirecting.. </h1>
      </div>
      <OuterSpinner></OuterSpinner>
    </div>
  );
};

export default Location;
