import React, { useState } from "react";
import { makeStyles, Snackbar, Dialog } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  root: {
    zIndex: "99999",
  },
});

function Toast(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.status ?? false);

  const handleCloseToast = (event, reason) => {
    setOpen(false);
  };

  if (props.dialog) {
    return (
      <Snackbar
        className={classes.root}
        open={open}
        autoHideDuration={props.autoHideDuration ?? 6000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={props.type} variant="filled">
          {props.message}
        </Alert>
      </Snackbar>
    );

  } else {
    return (
      <Dialog
        className={classes.root}
        open={open}
        autoHideDuration={props.autoHideDuration ?? 6000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={props.type} variant="filled">
          {props.message}
        </Alert>
      </Dialog>
    );
  }

}

export default Toast;
