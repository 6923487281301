import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import NavBarFixed from "../components/NavBarFixed";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { useHistory, Link } from "react-router-dom";
import {
  fetchFileName,
  prepareSearchFormListForHighlight,
  getSearchFiltersFromQuery,
  getSearchURLParams,
} from "../utils/helper_functions";
import { Trans, useTranslation } from "react-i18next";
import Toast from "../components/Toast";
import useFindOnPage from "../utils/hooks/useFindOnPage";
import FindOnPage from "../components/FindOnPage";
import BackToTop from "../components/BackToTop";
import {
  CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS,
  SEARCH_TYPE,
} from "../utils/Constants";
import { ReactSession } from "react-client-session";

const useStyles = makeStyles((theme) => ({
  toparea: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      marginBottom: "60px",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  titlearea: {
    marginBottom: "40px",
  },
  abtn: {
    fontFamily: ["Gotham"],
    fontSize: "16px",
    margin: "4.5px 0px",
    "&.MuiButtonGroup-grouped": {
      minWidth: "30px",
      minHeight: "30px",
      maxWidth: "30px",
      maxHeight: "30px",
    },
    "&.MuiButtonGroup-groupedTextPrimary:not(:last-child)": {
      border: "none!important",
    },
  },
  alphabetBtn: {
    display: "flex",
    flexDirection: "column",
  },
  resultpaper: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    padding: "20px",
  },
  section: {
    paddingBottom: "90px",
  },
  btnarea: {
    display: "flex",
    marginBottom: "60px",
  },
  btn: {
    marginRight: "10px",
  },
  nav: {
    marginBottom: "60px",
  },
  sectionMain: {
    marginTop: "30px",
    wordWrap: "break-word",
  },
  input: {
    marginRight: "10px",
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Gotham-Book","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
      opacity: "unset!important",
      textOverflow: "ellipsis",
    },
  },
  tableContainer: {
    maxWidth: "calc(100vw - 50px )",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  tableHeader: {
    fontSize: "17px",
    fontFamily: '"Gotham-Medium"',
    lineHeight: "24px",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
    },
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navigate: {
    display: "flex",
    height: "45px",
    "& button": {
      height: "100%",
      minWidth: "170px",
    },
  },
  backbtn: {
    marginRight: "10px",
  },
  navigationIcon: {
    marginTop: "-1px",
  },
  findOnPage: {
    maxWidth: "100%",
    minWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "355px",
      minWidth: "355px",
    },
  },
  bottomStickyGroup: {
    position: "sticky",
    left: "95%",
    top: "90%",
    display: "inline-flex",
    justifyContent: "end",
    transition: "opacity 0.4s",
  },
  mobileFindOnPage: {
    marginTop: "30px",
    marginBottom: "30px",
    height: "55px",
  },
  rowHighlighted: {
    backgroundColor: "var(--highlight-color) !important",
  },
}));
function IndexEntry(props) {
  const { t } = useTranslation();
  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;
  const classes = useStyles();
  const currentIndex = currentUrlParams.get("currentIndex");
  const nextPackageType = currentUrlParams.get("nextPackageType");
  const prevPackageType = currentUrlParams.get("prevPackageType");
  const [entry, setEntry] = React.useState({});

  const rowId = currentUrlParams.get("rowId");

  const searchURL = useRef(currentUrlParams.get("search_url"));
  const { alphabetSearch, searchForm } = getSearchFiltersFromQuery(
    new URLSearchParams(decodeURIComponent(searchURL.current ?? ""))
  );
  const createSortHref = () => {
    const sortParams = new URLSearchParams();

    if (searchURL.current) {
      sortParams.set("search_url", searchURL.current);
    }

    return sortParams.toString();
  };

  const getAPIQueryParams = () => {
    if (!searchURL.current) {
      return "";
    }
    const searchURLParams = getSearchURLParams(searchURL.current);
    const sortParams = new URLSearchParams();

    const {
      searchType,
      sortBy,
      sortType,
      alphabetSearch,
      alphabetSearchField,
    } = getSearchFiltersFromQuery(searchURLParams);

    if (sortBy !== null) {
      sortParams.append("sortBy", sortBy);
      if (sortType === true || sortType === "true") {
        sortParams.append("ascending", true);
      } else {
        sortParams.append("ascending", false);
      }
    }
    if (searchType === SEARCH_TYPE.ALPHABETICAL_SEARCH) {
      sortParams.append("prefix", alphabetSearch);
      sortParams.append("type", "alphabet");
      sortParams.append("fieldType", alphabetSearchField);
    }
    return sortParams.toString();
  };

  const createNextHref = () => {
    const nextParams = new URLSearchParams();
    nextParams.append("currentIndex", parseInt(currentIndex) + 1);
    nextParams.append("prevPackageFileName", props.match.params.fileName);
    nextParams.append("prevPackageType", "Index");
    return nextParams.toString() + "&" + createSortHref();
  };

  const createPrevhref = () => {
    const prevParams = new URLSearchParams();

    prevParams.append("currentIndex", parseInt(currentIndex) - 1);
    prevParams.append("nextPackageFileName", props.match.params.fileName);
    prevParams.append("nextPackageType", "Index");
    return prevParams.toString() + "&" + createSortHref();
  };
  const login = currentUrlParams.get("login");
  const [loginSnack, setLoginSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
    }
  };
  const nextId = currentUrlParams.get("nextPackageFileName");
  const prevId = currentUrlParams.get("prevPackageFileName");
  // const paramId = props.match.params.id;
  const nextHref = createNextHref();
  const prevHref = createPrevhref();
  const history = useHistory();
  const navigateToValidate = () => {
    history.replace({
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });
  };
  // const StyledTableCell = withStyles((theme) => ({

  //     body: {
  //         fontSize: 14,
  //     },
  // }))(TableCell);

  // const StyledTableRow = withStyles((theme) => ({
  //     root: {
  //         '&:nth-of-type(odd)': {
  //             backgroundColor: theme.palette.action.hover,
  //         },
  //         '&:nth-of-type(even)': {
  //             backgroundColor: '#ffffff',
  //         },
  //     },
  // }))(TableRow);

  const canGoBackToSearchResult = () => {
    if (searchURL?.current && searchURL.current.length) {
      return true;
    }
    return false;
  };

  const prepareIndexData = (data) => {
    return data
      ?.replaceAll("<title", "<note")
      ?.replaceAll("</title>", "</note>");
  };

  const tabledata = () => {
    return (
      <div className={classes.tableContainer}>
        <table
          className={classes.table + " citTable indexTable"}
          cellSpacing="0"
        >
          <tbody>
            <tr className="tablehead searchIgnore">
              <td className={classes.tableHeader} style={{ display: "none" }}>
                #
              </td>
              <td className={classes.tableHeader} align="left">
                aetas
              </td>
              <td className={classes.tableHeader} align="left">
                notae
              </td>
              <td className={classes.tableHeader} align="left">
                &nbsp;
              </td>
              <td className={classes.tableHeader} align="left">
                notarum explicatio
              </td>
              <td className={classes.tableHeader} align="left">
                editiones
              </td>
            </tr>
            {entry?.table?.map((el, index0) => {
              return (
                <tr
                  key={"citation." + index0 + "." + el.rowId}
                  id={el.rowId}
                  className={el.rowId == rowId ? classes.rowHighlighted : ""}
                >
                  <td
                    className="tll insidedata"
                    style={{ display: "none" }}
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.number) ?? "",
                    }}
                  ></td>
                  <td
                    className="tll insidedata"
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.aetas) ?? "",
                    }}
                  ></td>
                  <td
                    className="tll insidedata"
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.notae) ?? "",
                    }}
                  ></td>
                  <td
                    className="tll insidedata"
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.fourthCol) ?? "",
                    }}
                  ></td>
                  <td
                    className="tll insidedata"
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.notarumExplicatio) ?? "",
                    }}
                  ></td>
                  <td
                    className="tll insidedata"
                    dangerouslySetInnerHTML={{
                      __html: prepareIndexData(el.editiones) ?? "",
                    }}
                  ></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    checklogin();
    if (
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("jwtToken") === undefined
    ) {
      navigateToValidate();
    } else {
      loadData();
    }
  }, [props.match.params.fileName]);

  useEffect(() => {
    let aid = "1598265";
    let aname = "";
    if (
      localStorage.getItem("aid") !== undefined &&
      localStorage.getItem("aid") !== null
    ) {
      aid = localStorage.getItem("aid");
    }
    if (
      localStorage.getItem("aname") !== undefined &&
      localStorage.getItem("aname") !== null
    ) {
      aname = localStorage.getItem("aname");
    }
    const script = document.createElement("script");
    if (entry?.title) {
      script.type = "text/javascript";
      script.innerHTML = `
        var _ll = _ll || [];
        _ll.push(
          [ "trackItemRequest",
            {        
              "aid": '${aid}',
              "aname": '${aname}',
              "url": '${window.location.href}',
              "sid": '${ReactSession.get("sid")}',
              
              "at": "controlled",
              "am": "regular",
              "im": {
                "title": '${entry?.title ? entry.title : ""}',
                "dtype": "article",
                "id": 'TLL_${entry?.title ? entry.title : ""}',
              }
            }
          ]);
        `;
    }
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [entry?.title]);

  const loadData = () => {
    const apiUrlSearchParams = new URLSearchParams();
    // apiUrlSearchParams.append('id', props.match.params.id);
    const nextRequired = nextId === null || nextId === undefined;
    if (nextRequired && searchURL.current) {
      apiUrlSearchParams.append("nextRequired", true);
    }

    const prevRequired = prevId === null || prevId === undefined;
    if (prevRequired && searchURL.current) {
      apiUrlSearchParams.append("prevRequired", true);
    }

    if (
      currentIndex !== null &&
      currentIndex !== undefined &&
      currentIndex !== "null"
    ) {
      apiUrlSearchParams.append("currentIndex", currentIndex);
    }
    let token = localStorage.getItem("jwtToken");
    trackPromise(
      axios
        .post(
          process.env.REACT_APP_SERVER_HOST_NAME +
            `/rest/index/${props.match.params.fileName}.xml?` +
            apiUrlSearchParams.toString() +
            "&" +
            getAPIQueryParams(),
          searchForm,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          const uParas = new URLSearchParams();
          if (nextRequired) {
            if (response.data?.nextPackageFileName) {
              uParas.append(
                "nextPackageFileName",
                fetchFileName(response.data?.nextPackageFileName)
              );
              const isNextArticle =
                response.data?.nextPackageType
                  ?.toLowerCase()
                  ?.indexOf("articledata") !== -1;
              uParas.append(
                "nextPackageType",
                isNextArticle ? "Article" : "Index"
              );
            }
          } else {
            uParas.append("nextPackageFileName", nextId);
            uParas.append("nextPackageType", nextPackageType);
          }

          if (prevRequired) {
            if (response.data?.prevPackageFileName) {
              uParas.append(
                "prevPackageFileName",
                fetchFileName(response.data?.prevPackageFileName)
              );
              const isPrevArticle =
                response.data?.prevPackageType
                  ?.toLowerCase()
                  ?.indexOf("articledata") !== -1;
              uParas.append(
                "prevPackageType",
                isPrevArticle ? "Article" : "Index"
              );
            }
          } else {
            uParas.append("prevPackageFileName", prevId);
            uParas.append("prevPackageType", prevPackageType);
          }

          if (currentIndex) {
            uParas.append("currentIndex", currentIndex);
          }

          if (rowId) {
            uParas.append("rowId", rowId);
          }

          history.replace(
            `/index/${props.match.params.fileName}?${
              uParas.toString() + "&" + createSortHref()
            }`
          );
          setEntry(response.data);
          if (rowId) {
            const elmnt = document.getElementById(rowId);
            elmnt.scrollIntoView();
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigateToValidate();
          }
          console.log("failed");
        })
    );
  };
  const titleprop = {
    search: [{ value: "" }],
  };
  titleprop.search[0].value = entry?.title ? entry.title : "";
  const backToResults = () => {
    history.push(decodeURIComponent(searchURL?.current));
  };

  const goBack = () => {
    history.goBack();
  };

  const [findPermanentString] = useFindOnPage("current", ".resultdata", true);

  // To mark search result query
  // search after data gets loaded
  useEffect(() => {
    if (searchForm?.search) {
      const searchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "INDEX_LIBRORUM")
      );
      findPermanentString(searchValues, false, true);
    }
  }, [entry]);

  return (
    <div className="body-container">
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      <OuterSpinner />
      <NavBarFixed values={searchForm} bglight={true} prefix={alphabetSearch} />
      <div className="main">
        <section className={classes.section + " section"}>
          <Container className="container">
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <div
                  className={
                    classes.sectionMobile +
                    " space-between " +
                    classes.mobileFindOnPage
                  }
                >
                  <FindOnPage
                    highlightClass={CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS}
                    searchWithinClassName=".resultdata"
                    showStickyOnMobile={true}
                  />
                </div>
                <div
                  className={
                    classes.sectionDesktop + " space-between " + classes.toparea
                  }
                >
                  <div className={classes.navigate}>
                    {prevId ? (
                      <span className={classes.backbtn}>
                        <Link
                          to={{
                            pathname: `/${prevPackageType.toLowerCase()}/${prevId}`,
                            search: prevHref,
                          }}
                        >
                          <Button
                            square
                            variant="outlined"
                            classes={{
                              startIcon: classes.navigationIcon,
                            }}
                            startIcon={
                              <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11L1 6L6 1"
                                  stroke="#121212"
                                  strokeWidth="1.5"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                          >
                            {t("prevResult")}
                          </Button>
                        </Link>
                      </span>
                    ) : null}

                    {canGoBackToSearchResult() ? (
                      <span className={classes.backbtn}>
                        <Button
                          onClick={backToResults}
                          square
                          variant="outlined"
                        >
                          <Trans i18nKey="searchResults">Search Results</Trans>
                        </Button>
                      </span>
                    ) : (
                      <span className={classes.backbtn}>
                        <Button onClick={goBack} square variant="outlined">
                          <Trans i18nKey="goBack">Go Back</Trans>
                        </Button>
                      </span>
                    )}

                    {nextId ? (
                      <span className={classes.backbtn}>
                        <Link
                          to={{
                            pathname: `/${nextPackageType.toLowerCase()}/${nextId}`,
                            search: nextHref,
                          }}
                        >
                          <Button
                            square
                            variant="outlined"
                            classes={{
                              endIcon: classes.navigationIcon,
                            }}
                            endIcon={
                              <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1L6 6L1 11"
                                  stroke="#121212"
                                  strokeWidth="1.5"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                          >
                            {t("nextResult")}
                          </Button>
                        </Link>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={classes.titlearea}>
                  <div className="results">
                    <Typography
                      variant="h1"
                      style={{
                        wordWrap: "anywhere",
                        paddingRight: "50px",
                        fontFamily: "Gotham-Book, tllmedium",
                        fontWeight: "bold",
                      }}
                    >
                      “{entry?.title ? entry.title : ""}”
                    </Typography>
                  </div>
                </div>
                <div className="resultdata">{tabledata()}</div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div
                  className={classes.sectionDesktop}
                  style={{
                    marginLeft: "3px",
                    marginTop: "20px",
                    position: "sticky",
                    top: "120px",
                    left: "0px",
                  }}
                >
                  <FindOnPage
                    highlightClass={CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS}
                    searchWithinClassName=".resultdata"
                  />
                </div>
                <div className={classes.bottomStickyGroup}>
                  <BackToTop />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default IndexEntry;
