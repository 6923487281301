import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

function SearchButton(props) {
  const { t } = useTranslation();
  return (
    // disableElevation
    <Button
      variant="contained"
      color="primary"
      className="btn search-btn"
      type="submit"
      onClick={() => props.close()}
      value="submit"
    >
      {t("Search")}
    </Button>
  );
}

export default SearchButton;
