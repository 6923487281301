import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
function SearchButtonMobile(props) {
  const { t } = useTranslation();

  return (
    // disableElevation
    <Button
      variant="contained"
      color="primary"
      style={{ backgroundColor: "#005d78" }}
      className="btn mobile-search-btn"
      type="submit"
      onClick={() => props.close()}
      value="submit"
    >
      {t("Search")}
    </Button>
  );
}

export default SearchButtonMobile;
