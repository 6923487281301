import { toRoman } from "roman-numerals";

export const getTLLString = (entry) => {
  // tll string:
  // TLL X.1.2141.43–60 (Ottink 2003).

  var tllString = getTLLCitation(entry);
  tllString += " (" + entry.author + " " + entry.year + ").";

  // var tllString =
  //   entry.author + " " + entry.year + ". " + entry.lemmaText + ". ";
  // tllString += getTLLCitation(entry) + ". ";
  // tllString += "Berlin, New York: DeGruyter, " + entry.year + ". ";
  // tllString +=
  //   "Available from: https://tll.degruyter.com/article/" +
  //   entry.xmlfileName.replace(".xml", "") +
  //   ". ";

  //[Accessed 2023-03-24]
  // const now = new Date();
  // const year = now.getFullYear();
  // const month = now.getMonth() + 1;
  // const day = now.getDate();
  // tllString += "[Accessed " + year + "-" + month + "-" + day + "].";

  return tllString;
};

const getTLLCitation = (entry) => {
  // TLL - predefined
  // white space
  // volume number in roman
  // fullstop
  // part number in arabic
  // fullstop
  var tllString = "TLL ";
  if (entry?.volume) {
    if (entry?.volume > 0) {
      tllString += toRoman(entry.volume);
    } else {
      tllString += entry?.volume; // we could use N or nulla
    }
  } else {
    tllString += "VOLUME";
  }
  if (entry?.pars) {
    tllString += "." + entry.pars;
  } else {
    tllString += ".PART";
  }
  tllString += ".";

  if (entry?.fromPage === entry?.toPage) {
    // page number in arabic
    // fullstop
    // line number in arabic
    // hyphen
    // line number in arabic
    tllString += entry.fromPage + ".";
    tllString += entry.firstLine + "-" + entry.lastLine;
  } else {
    // part number in arabic
    // fullstop
    // page number in arabic
    // fullstop
    // line number in arabic
    // hyphen
    // page number in arabic
    // fullstop
    // line number in arabic
    tllString += entry.fromPage + "." + entry.firstLine;
    tllString += "-";
    tllString += entry.toPage + "." + entry.lastLine;
  }
  return tllString;
};
