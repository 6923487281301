import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "30px",
    "& h6": {
      fontFamily: "Merriweather",
      fontWeight: 700,
    },
  },
  accordion: {
    minWidth: "max-content",
  },
  accordionSummary: {
    padding: "0px !important",
    maxWidth: "max-content",
    height: "56px !important",
    maxHeight: "56px !important",
    minHeight: "56px !important",
  },
  accordionDetails: {
    paddingLeft: "0px !important",
    paddingBottom: "0px !important",
  },
  expandIcon: {
    color: "#121212",
  },
}));
function EntryMeta({
  volume,
  pars,
  firstPage,
  firstLine,
  lastPage,
  lastLine,
  author,
  publishYear,
}) {
  const classes = useStyles();

  const getMetaTitleString = () => {
    if (firstPage === lastPage && firstLine === lastLine) {
      return `Vol. ${volume}, ${pars}, ${firstPage}, ${firstLine} (${author} ${publishYear}).`;
    }
    return `Vol. ${volume}, ${pars}, ${firstPage}, ${firstLine} - ${lastPage}, ${lastLine} (${author} ${publishYear}).`;
  };

  const getMetaDetailString = () => {
    if (firstPage === lastPage && firstLine === lastLine) {
      return `p. ${firstPage}, lin. ${firstLine}`;
    }
    return `p. ${firstPage}, lin. ${firstLine} - p. ${lastPage}, lin. ${lastLine}`;
  };

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography variant="h6">{getMetaTitleString()}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <dl className="inline">
            <dt>
              <Trans i18nKey="in">In</Trans>
            </dt>
            <dd>{`Vol. ${volume}, ${pars}`}</dd>

            <dt>
              <Trans i18nKey="scope">Scope</Trans>
            </dt>
            <dd>{getMetaDetailString()}</dd>

            <dt>
              <Trans i18nKey="published">Published</Trans>
            </dt>
            <dd>{publishYear}</dd>

            <dt>
              <Trans i18nKey="author">Author</Trans>
            </dt>
            <dd>{author}</dd>
          </dl>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

EntryMeta.propTypes = {
  volume: PropTypes.string,
  firstPage: PropTypes.string,
  firstLine: PropTypes.string,
  lastPage: PropTypes.string,
  lastLine: PropTypes.string,
  author: PropTypes.string,
  publishYear: PropTypes.string,
};

export default EntryMeta;
