import React, { useEffect, useState } from "react";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as constants from "../utils/Constants";
import OuterSpinner from "../components/Spinner/OuterSpinner";

function Test() {
  const [data, setData] = useState();

  var token = localStorage.getItem("jwtToken");
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    trackPromise(
      axios
        .get(process.env.REACT_APP_SERVER_HOST_NAME + "/rest/hello", {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setData(response.data);
        })
        .catch(() => console.log("failed"))
    );
  };
  return (
    <div>
      <OuterSpinner />
    </div>
  );
}

export default Test;
