import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import clsx from "clsx";
import jsesc from "jsesc";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { ReactSession } from "react-client-session";
import { Trans, useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory } from "react-router-dom";
import BackToTop from "../components/BackToTop";
import CiteButton from "../components/CiteButton";
import DownloadButton from "../components/DownloadButton";
import EntryMeta from "../components/EntryMeta";
import EntryTabs from "../components/EntryTabs/EntryTabs";
import FindOnPage from "../components/FindOnPage";
import Footer from "../components/Footer";
import NavBarFixed from "../components/NavBarFixed";
import ShareButton from "../components/ShareButton";
import OuterSpinner from "../components/Spinner/OuterSpinner";
import Toast from "../components/Toast";
import * as constants from "../utils/Constants";
import commonStyles from "../utils/commonStyles";
import { getTLLString } from "../utils/getTLLString";
import {
  fetchFileName,
  getSearchFiltersFromQuery,
  getSearchURLParams,
  isJWTPresent,
  isNotNullAndEmptyStr,
  minimizeXML,
  prepareSearchFormListForHighlight,
  processXMLSenseDOM,
} from "../utils/helper_functions";
import useFindOnPage from "../utils/hooks/useFindOnPage";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  toparea: {
    display: "flex",
    marginTop: "30px",

    marginBottom: "60px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  titlearea: {
    [theme.breakpoints.up("md")]: {
      // position: "sticky",
      // top: "115px",
    },
    paddingTop: "0.5rem",
    paddingBottom: "1.5rem",
    // background: "#F6F6F8",
    background: "linear-gradient(0deg, transparent, #f6f6f8 40%)",
    zIndex: 999,
  },
  results: {
    lineHeight: "40px",
    fontFamily: "Merriweather, tllmedium",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      lineHeight: "50px",
    },
  },
  findpaper: {
    width: "100%",
    padding: "2px 3px 2px 25px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    border: "0.5px solid #A4A4A4",
    [theme.breakpoints.up("md")]: {
      minWidth: "355px",
      maxWidth: "355px",
    },
  },
  findpapermobile: {
    width: "100%",
    padding: "12px 20px 13px 25px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff",
    border: "0.5px solid #A4A4A4",
  },
  icons: {
    borderLeft: "1px solid #dddddd",
    borderRadius: "0",
  },
  icon: {
    width: "39px",
    height: "39px",
    backgroundColor: "#005d781a !important",
    marginLeft: "3px!important",
  },
  lineNumberToggleButton: {
    height: "45px",
    marginRight: "15px",
    width: "153px !important",
    textTransform: "initial !important",
  },
  // abtn: {
  //     fontFamily: ['Gotham'],
  //     fontSize: '16px',
  //     margin: '4.5px 0px',
  //     '&.MuiButtonGroup-grouped': {
  //         minWidth: '30px',
  //         minHeight: '30px',
  //         maxWidth: '30px',
  //         maxHeight: '30px',
  //     },
  //     '&.MuiButtonGroup-groupedTextPrimary:not(:last-child)': {
  //         border: 'none!important',
  //     },
  // },
  // alphabetBtn: {
  //     display: 'flex',
  //     flexDirection: 'column',
  // },
  resultpaper: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    padding: "20px",
  },
  section: {
    paddingBottom: "90px",
  },
  btnarea: {
    display: "flex",
    marginBottom: "40px",
  },
  btn: {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50px",
      maxHeight: "50px",
      minWidth: "50px",
      minHeight: "50px",
    },

    "& button.iconButton": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      minWidth: "100% !important",
      minHeight: "100% !important",
    },
  },
  btnareamobile: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "60px",
  },
  btnmobile: {
    marginRight: "10px",
    border: "1px solid #a4a4a4",
    minWidth: "50px",
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nav: {
    marginBottom: "60px",
  },
  sectionMain: {
    marginTop: "30px",
    wordWrap: "break-word",
  },
  input: {
    marginRight: "10px",
    "& .MuiInputBase-input::placeholder": {
      fontFamily: '"Merriweather","sans-serif" !important',
      fontSize: "16px !important",
      color: "#717173 !important",
      opacity: "unset!important",
      textOverflow: "ellipsis",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
    },
  },
  sectionMobile: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
  navigate: {
    display: "flex",
    height: "45px",
    "& button": {
      height: "100%",
      whiteSpace: "nowrap",
      minWidth: "170px",
    },
  },
  backbtn: {
    marginRight: "10px",
  },
  navigationIcon: {
    marginTop: "-1px",
  },
  mobileFindOnPage: {
    marginTop: "30px",
    marginBottom: "30px",
    height: "55px",
  },
  mobileFindOnPageSticky: {
    position: "sticky",
    top: "70px",
    left: "0px",
    display: "inline-block",
  },
  buttonGroup: {
    marginBottom: "40px",
    display: "flex",
    width: "100%",
    "& .iconButton": {
      minHeight: "50px",
      minWidth: "50px",
      border: "1px solid #005D78",
      background: "#E5EFF1",
    },
    "& .iconButton svg": {
      height: "50px",
    },
  },
  bottomStickyGroup: {
    position: "sticky",
    top: "90%",
  },
  bottomStickyGroupDiv: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "flex-end",
    transition: "opacity 0.4s",
  },
  stickyFindOnPage: {
    marginLeft: "10px",
    position: "sticky",
    top: "130px",
    height: "100%",
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
    marginBottom: "-100%",
    zIndex: "1000",
    pointerEvents: "none",
    // left: "0px",
  },
  hidden: {
    opacity: "0",
  },
  highlightClass: {
    background: "var(--highlight-color) !important",
  },
}));
function Entry(props) {
  const { t } = useTranslation();

  const currentUrl = new URL(window.location.href);
  const currentUrlParams = currentUrl.searchParams;
  const [showLineNumbers, setShowLineNumbers] = useState(false);
  const [tabDataLoaded, setTabDataLoaded] = useState([]);
  const classes = useStyles();
  const token = localStorage.getItem("jwtToken");
  const currentIndex = currentUrlParams.get("currentIndex");
  const fileName = props.match?.params?.fileName;
  const locationHash = props.location.hash;
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const searchURL = useRef(currentUrlParams.get("search_url"));
  const decodedSearchUrl = useMemo(() => {
    return new URLSearchParams(
      decodeURIComponent(searchURL.current ?? "").split("?")?.[1] ?? ""
    );
  }, [searchURL.current]);

  const { alphabetSearch, searchForm } = useMemo(() => {
    return getSearchFiltersFromQuery(decodedSearchUrl);
  }, [decodedSearchUrl]); // Add dependency

  const mobileFindOnPageRef = useRef(null);
  const entryTabsRef = useRef(null);
  const createSortHref = () => {
    const sortParams = new URLSearchParams();

    if (searchURL.current) {
      sortParams.set("search_url", searchURL.current);
    }

    return sortParams.toString();
  };

  const getAPIQueryParams = () => {
    if (!searchURL.current) {
      return "";
    }
    const searchURLParams = getSearchURLParams(searchURL.current);
    const sortParams = new URLSearchParams();

    const {
      searchType,
      sortBy,
      sortType,
      alphabetSearch,
      alphabetSearchField,
    } = getSearchFiltersFromQuery(searchURLParams);

    if (sortBy !== null) {
      sortParams.append("sortBy", sortBy);
      if (sortType === true || sortType === "true") {
        sortParams.append("ascending", true);
      } else {
        sortParams.append("ascending", false);
      }
    }
    if (searchType === constants.SEARCH_TYPE.ALPHABETICAL_SEARCH) {
      sortParams.append("prefix", alphabetSearch);
      sortParams.append("type", "alphabet");
      sortParams.append("fieldType", alphabetSearchField);
    }
    return sortParams.toString();
  };

  const createNextHref = () => {
    const nextParams = new URLSearchParams();
    nextParams.append("currentIndex", parseInt(currentIndex) + 1);
    nextParams.append("prevPackageFileName", props.match.params.fileName);
    nextParams.append("prevPackageType", "Article");
    return nextParams.toString() + "&" + createSortHref();
  };

  const createPrevhref = () => {
    const prevParams = new URLSearchParams();

    prevParams.append("currentIndex", parseInt(currentIndex) - 1);
    prevParams.append("nextPackageFileName", props.match.params.fileName);
    prevParams.append("nextPackageType", "Article");
    return prevParams.toString() + "&" + createSortHref();
  };
  const login = currentUrlParams.get("login");
  const [loginSnack, setLoginSnack] = React.useState(false);
  const checklogin = () => {
    if (login === "success") {
      setLoginSnack(true);
    }
  };

  const nextId = currentUrlParams.get("nextPackageFileName");
  const prevId = currentUrlParams.get("prevPackageFileName");
  const nextPackageType = currentUrlParams.get("nextPackageType");
  const prevPackageType = currentUrlParams.get("prevPackageType");
  const nextHref = createNextHref();
  const prevHref = createPrevhref();

  const history = useHistory();
  // const paramId = window.location.search;
  const [entry, setEntry] = React.useState({});
  const [currentTab, setCurrentTab] = useState(constants.EntryTabs.LEMMA_TAB);

  const [loading, setLoading] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  // const { promiseInProgress } = usePromiseTracker({ area: "wayflessValidate" });

  const _llca = currentUrlParams.get("_llca");
  const _llch = currentUrlParams.get("_llch");
  let isWayflessParamsPresent =
    isNotNullAndEmptyStr(_llca) && isNotNullAndEmptyStr(_llch);

  const navigateToValidate = () => {
    history.replace("/validate", {
      pathname: "/validate",
      search: `redirect_url=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`,
    });

    // history.replace({
    //   pathname: "/validate",
    //   search: `redirect_url=${encodeURIComponent(
    //     window.location.pathname + window.location.search
    //   )}`,
    // });
  };

  // useEffect(() => {
  //   console.log("Token Change");
  //   if (getIsJWTPresent()) {
  //     console.log("Load data after token set");
  //     loadData();
  //   }
  // }, [token]);

  useEffect(() => {
    if (props.wayflessValidation === "failed" && !isJWTPresent()) {
      navigateToValidate();
    }
  }, [props.wayflessValidation]);

  // useEffect(() => {
  //   if (isWayflessParamsPresent)
  //     setTimeout(() => {
  //       if (!getIsJWTPresent()) navigateToValidate();
  //     }, 1000);
  // }, []);

  // useEffect(() => {
  //   if (!getIsJWTPresent()) {
  //     if (isWayflessParamsPresent) {
  //       if (!promiseInProgress) {
  //         trackPromise(
  //           axios
  //             .post(
  //               constants.VALIDATE_USER_API,
  //               constants.getValidateUserAPIData()
  //             )
  //             .then((response) => {
  //               if (isNotNullOrUndefined(response?.data?.jwtToken)) {
  //                 onValidateUserSuccessResponse(response.data);
  //               } else {
  //                 history.replace({
  //                   pathname: "/validate",
  //                   search: `redirect_url=${encodeURIComponent(
  //                     window.location.pathname + window.location.search
  //                   )}`,
  //                 });
  //               }
  //             })
  //             .catch(() => console.log("user validation failed")),
  //           "wayflessValidate"
  //         );
  //       }
  //     }
  //   }
  // }, []);

  useEffect(() => {
    checklogin();
    const freeArticles = [
      "11_2_5_regina_vf_11082018",
      "resocio_v1_22032024",
      "11-2-9_rescellula_vf_28032023",
    ];
    console.log("fileName", fileName);

    if (freeArticles.includes(fileName) || isJWTPresent()) {
      setTabDataLoaded(() => []);
    } else {
      if (!isWayflessParamsPresent) {
        navigateToValidate();
      } else {
        // shouldn't we setTabDataLoaded to [] here?
      }
    }
  }, [props.match.params.fileName]);

  useEffect(() => {
    if (!contentLoaded) return;
    const aid = localStorage.getItem("aid"); //?? "1598265"
    if (!aid || aid === "") {
      // don't track requests if aid is not present
      // console.info("// don't track requests if aid is not present");
      return;
    }

    const aname = localStorage.getItem("aname") ?? "";
    const script = document.createElement("script");
    if (entry?.lemma) {
      script.type = "text/javascript";
      script.innerHTML = `
      var _ll = _ll || [];
      _ll.push(["trackItemRequest", {
          "aid": '${aid}',
          "aname": '${aname}',
          "url": '${window.location.href}',
          "sid": '${ReactSession.get("sid")}',
          "at": "controlled",
          "am": "regular",
          "im": {
            "title": '${jsesc(entry?.lemma?.length ? entry.lemma[0] : "")}',
            "dtype": "article",
            "id": 'TLL_${fileName ?? ""}',
          }
        }]);
      `;
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [entry?.lemma, contentLoaded]);

  const canGoBackToSearchResult = () => {
    if (searchURL?.current && searchURL.current.length) {
      return true;
    }
    return false;
  };

  const sortCitations = (citations) => {
    citations = citations.map((c) => {
      c.fullName = minimizeXML(c.refColumn);
      // strip all html tags
      c.fullName = c.fullName.replace(/(<([^>]+)>)/gi, "").trim();
      // strip tabs and newlines
      c.fullName = c.fullName.replace(/[\t\r\n]/g, " ");
      // replace all multiple spaces with single space
      c.fullName = c.fullName.replace(/\s+/g, " ");
      // remove colon from the beginning of the string
      c.fullName = c.fullName.replace(/^:/, "");
      c.fullName = c.fullName.trim();
      return c;
    });

    const collator = new Intl.Collator();

    // sort by fullName
    citations.sort((a, b) => {
      let aName = a.fullName;
      let bName = b.fullName;
      return aName.localeCompare(bName, collator, { numeric: true });
    });

    return citations;
  };

  const loadData = () => {
    if (loading) return;

    setLoading(true);
    const apiUrlSearchParams = new URLSearchParams();
    const nextRequired = nextId === null || nextId === undefined;
    if (nextRequired && !tabDataLoaded?.length) {
      apiUrlSearchParams.append("nextRequired", true);
    }

    const prevRequired = prevId === null || prevId === undefined;
    if (prevRequired && !tabDataLoaded?.length) {
      apiUrlSearchParams.append("prevRequired", true);
    }

    const tempCurrentTab = currentTab; // TODO: If tab changes in between API call;

    apiUrlSearchParams.append(
      "articleTab",
      tempCurrentTab === constants.EntryTabs.CITE_TAB ? "CITATION" : "LEMMA"
    );

    if (
      currentIndex !== null &&
      currentIndex !== undefined &&
      currentIndex !== "null"
    ) {
      apiUrlSearchParams.append("currentIndex", currentIndex);
    }

    mobileFindOnPageRef.current?.searchValue("");

    const url =
      process.env.REACT_APP_SERVER_HOST_NAME +
      `/rest/article/${fileName}.xml?` +
      apiUrlSearchParams.toString() +
      "&" +
      getAPIQueryParams();

    // console.log("url", url);

    trackPromise(
      axios
        .post(url, searchForm, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          const uParams = new URLSearchParams();
          if (nextRequired) {
            if (response.data?.nextPackageFileName) {
              uParams.append(
                "nextPackageFileName",
                fetchFileName(response.data?.nextPackageFileName)
              );
              const isNextArticle =
                response.data?.nextPackageType
                  ?.toLowerCase()
                  ?.indexOf("articledata") !== -1;
              uParams.append(
                "nextPackageType",
                isNextArticle ? "Article" : "Index"
              );
            }
          } else {
            uParams.append("nextPackageFileName", nextId);
            uParams.append("nextPackageType", nextPackageType);
          }

          if (prevRequired) {
            if (response.data?.prevPackageFileName) {
              uParams.append(
                "prevPackageFileName",
                fetchFileName(response.data?.prevPackageFileName)
              );
              const isPrevArticle =
                response.data?.prevPackageType
                  ?.toLowerCase()
                  ?.indexOf("articledata") !== -1;
              uParams.append(
                "prevPackageType",
                isPrevArticle ? "Article" : "Index"
              );
            }
          } else {
            uParams.append("prevPackageFileName", prevId);
            uParams.append("prevPackageType", prevPackageType);
          }

          uParams.append("currentIndex", currentIndex);
          // if (tabDataLoaded.length === 0) {
          history.replace(
            `/article/${props.match.params.fileName}?${
              uParams.toString() + "&" + createSortHref() + `${locationHash}`
            }`
          );

          if (response.data) {
            response.data.newCitList = sortCitations(response.data.newCitList);
          }

          setEntry(response.data ?? {});

          // set both tabs as loaded
          setTabDataLoaded([
            constants.EntryTabs.LEMMA_TAB,
            constants.EntryTabs.CITE_TAB,
          ]);
          setContentLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 403) {
            console.log(error.message);
            if (props.wayflessValidation !== "success") {
              localStorage.removeItem("jwtToken");
            }

            if (
              !isWayflessParamsPresent ||
              props.wayflessValidation === "failed"
            ) {
              navigateToValidate();
            }
          }
        })
        .finally(() => {
          setLoading(false);
        })
    );
  };
  const lemmaprop = {
    search: [{ value: "" }],
  };
  lemmaprop.search[0].value = entry?.lemma ? entry.lemma[0] : "";

  const [ldata, setLdata] = useState(undefined);

  useEffect(() => {
    if (!contentLoaded) return;
    if (!entry?.fullXML?.length) {
      return;
    }

    const minimizedXML = minimizeXML(entry.fullXML);

    // const parser = new DOMParser();
    // const xmlDoc = parser.parseFromString(minimizedXML, "text/html");
    // console.log(xmlDoc);

    // console.log(processXMLSenseDOM(xmlDoc));
    // const parsedEntry = processXMLSenseDOM(xmlDoc);
    const parsedEntry = minimizedXML;

    setLdata(
      <div className={classes.ldata}>
        <div>
          <Typography
            variant="h6"
            component="p"
            color="textPrimary"
            className="tll insidedata"
            dangerouslySetInnerHTML={{ __html: parsedEntry }}
          ></Typography>
          {/* {parsedEntry} */}
        </div>
      </div>
    );
  }, [contentLoaded, entry?.fullXML]);

  const backToResults = () => {
    history.push(decodeURIComponent(searchURL?.current));
  };

  const [bodyFindString] = useFindOnPage(
    "permanentHighlight",
    ".lemma-search-div",
    true
  );
  const [citeFindString] = useFindOnPage(
    "permanentHighlight",
    ".cit-search-div",
    true
  );
  const goToNextBodyOccurrence = () => {
    document.querySelector(".permanentHighlight")?.scrollIntoView();
  };

  const goToNextCiteOccurence = () => {
    document.querySelector(".permanentHighlight")?.scrollIntoView();
  };

  // console.log("entry", entry);
  const [urlCiteString, setUrlCiteString] = useState(undefined);
  const [bodyString, setBodyString] = useState(undefined);
  const [scrollToId, setScrollToId] = useState(undefined);

  // To mark search result query
  // search after data gets loaded
  useEffect(() => {
    if (!contentLoaded) return;
    if (JSON.stringify(entry) == JSON.stringify({})) {
      return;
    }

    if (searchForm?.search) {
      const searchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "FULL_TEXT")
      );
      const citSearchValues = prepareSearchFormListForHighlight(
        searchForm.search.filter((e) => e.field === "CITATION")
      );
      if (currentTab === constants.EntryTabs.LEMMA_TAB) {
        setTimeout(() => {
          // Delaying this to mark the full text search while the line is being marked
          // The body string will not be scrolled to if the scrollToId element is set
          // if we're not wrapping this in setTimeout, the words won't be highlighted if a line/page is also highlighted
          setBodyString(searchValues);
        }, 0);
      } else if (currentTab === constants.EntryTabs.CITE_TAB) {
        setUrlCiteString(citSearchValues);
      }
    }

    if (decodedSearchUrl.get("type") == 1) {
      // search by vol, para, page, line
      const volume = decodedSearchUrl.get("volume") ?? entry.volume;
      const paragraph = decodedSearchUrl.get("paragraph") ?? entry.pars;
      let page = decodedSearchUrl.get("page");
      let line = decodedSearchUrl.get("line");

      if (page || line) {
        // show line numbers when searching for page or line
        setShowLineNumbers(true);

        // if the page is missing, try to find the first page.line match
        if (page == undefined) {
          // find first page
          let pageCandidate = entry.firstPage;
          let idNotFound = true;
          while (idNotFound) {
            const id = [volume, paragraph, pageCandidate, line].join(".");
            const el = document.getElementById(id);
            if (el) {
              page = pageCandidate;
              idNotFound = false;
            }

            if (pageCandidate > entry.lastPage) {
              idNotFound = false;
            }

            pageCandidate++;
          }
        }

        // if the line is missing, try to find the first page.line match
        if (line == undefined) {
          // find first line
          let lineCandidate = 0;
          let idNotFound = true;
          while (idNotFound) {
            const id = [volume, paragraph, page, lineCandidate].join(".");
            const el = document.getElementById(id);
            if (el) {
              line = lineCandidate;
              idNotFound = false;
            }

            if (lineCandidate > entry.lastLine) {
              idNotFound = false;
            }
            lineCandidate++;
          }
        }
      }

      // if all the values are present, scroll to the element
      if (volume && page && line && paragraph !== undefined) {
        const id = [volume, paragraph, page, line].join(".");
        setScrollToId(id);
      }
    } else if (locationHash?.length) {
      const targetElement = document.getElementById(locationHash.substring(1));
      targetElement?.scrollIntoView();
    }
  }, [entry?.lemma, contentLoaded, currentTab]);

  useEffect(() => {
    if (!contentLoaded || !urlCiteString?.length) return;
    citeFindString(urlCiteString, true, true, () => {
      goToNextCiteOccurence();
    });
  }, [urlCiteString, contentLoaded, currentTab]);
  // don't add citeFindString in dependency array - you'll break it

  useEffect(() => {
    if (!contentLoaded || !bodyString?.length) return;
    bodyFindString(bodyString, true, true, () => {
      if (!scrollToId) {
        goToNextBodyOccurrence();
      }
    });
  }, [bodyString, contentLoaded, currentTab]);
  // don't add bodyFindString in dependency array - you'll break it

  useEffect(() => {
    if (loading) return;
    if (tabDataLoaded.length == 0) {
      loadData();
    } else if (!tabDataLoaded.includes(currentTab)) {
      // should never happen - it's here from the old code
      loadData();
    }
  }, [contentLoaded, currentTab, tabDataLoaded?.length]);

  useEffect(() => {
    if (!contentLoaded) return;
    if (!searchForm?.search) return;

    const searchValues = prepareSearchFormListForHighlight(
      searchForm.search.filter((e) => e.field === "FULL_TEXT")
    );

    const citSearchValues = prepareSearchFormListForHighlight(
      searchForm.search.filter((e) => e.field === "CITATION")
    );

    if (
      citSearchValues.length &&
      !searchValues.length &&
      entryTabsRef?.current?.changeTab
    ) {
      entryTabsRef.current.changeTab(constants.EntryTabs.CITE_TAB);
    }
  }, [contentLoaded, searchForm?.search]);

  useEffect(() => {
    if (entry?.lemma) {
      setLemmaText(entry.lemma[0]);
    } else {
      setLemmaText(false);
    }
  }, [entry?.lemma]);

  const goToPrev = () => {
    // Go to this link

    if (prevId) {
      // router.push({
      //   pathname: `/${prevPackageType.toLowerCase()}/${prevId}`,
      //   search: prevHref,
      // });
      history.push(`/${prevPackageType.toLowerCase()}/${prevId}?${prevHref}`);
    }
  };

  const goToNext = () => {
    // Go to this link
    if (nextId) {
      // router.push({
      //   pathname: `/${nextPackageType.toLowerCase()}/${nextId}`,
      //   search: nextHref,
      // });
      history.push(`/${nextPackageType.toLowerCase()}/${nextId}?${nextHref}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      // Left arrow
      if (prevId) goToPrev();
    } else if (event.keyCode === 39) {
      // Right arrow
      if (nextId) goToNext();
    }
  };
  // handle keyboard navigation
  useEffect(() => {
    window.removeEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [entry?.lemma]);

  const scrollToElement = useRef(null);

  useEffect(() => {
    if (!scrollToId) return;
    const targetElement = document.getElementById(scrollToId);
    scrollToElement.current = targetElement;
    targetElement?.scrollIntoView({ block: "center", behavior: "instant" });
    targetElement?.classList.add(classes.highlightClass);
  }, [scrollToId]);

  const [lemmaText, setLemmaText] = useState("");

  const { ref: lemmaRef, inView: lemmaTitleVisible } = useInView();

  const [stickyFindOnPage, setStickyFindOnPage] = useState(false);

  useEffect(() => {
    // if (!contentLoaded) return;
    setStickyFindOnPage(
      <div className={classes.stickyFindOnPage}>
        {/* <div className={classes.sectionDesktop + " space-between"}> */}
        <div className={classes.navigate}>
          {isDesktop && entry?.id && (
            <div
              className={classes.sectionDesktop}
              style={{
                height: "100%",
                // left: "0px",
                pointerEvents: "visiblepainted",
              }}
            >
              {currentTab === constants.EntryTabs.LEMMA_TAB ? (
                <FindOnPage
                  key="pc-lemma-search"
                  highlightClass={
                    constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS
                  }
                  searchWithinClassName=".lemma-search-div"
                ></FindOnPage>
              ) : (
                <FindOnPage
                  key="pc-cit-search"
                  highlightClass={
                    constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS
                  }
                  searchWithinClassName=".cit-search-div"
                ></FindOnPage>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [contentLoaded, entry?.id]);

  const topArea = (
    <div className={classes.toparea + " " + classes.sectionDesktop}>
      <div className={classes.sectionDesktop + " space-between"}>
        <div className={classes.navigate}>
          {prevId ? (
            <span className={classes.backbtn}>
              <Link
                to={{
                  pathname: `/${prevPackageType.toLowerCase()}/${prevId}`,
                  search: prevHref,
                }}
              >
                <Button
                  square
                  variant="outlined"
                  classes={{
                    startIcon: classes.navigationIcon,
                  }}
                  startIcon={
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 11L1 6L6 1"
                        stroke="#121212"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  {t("prevResult")}
                </Button>
              </Link>
            </span>
          ) : null}

          {canGoBackToSearchResult() ? (
            <span className={classes.backbtn}>
              <Button onClick={backToResults} square variant="outlined">
                <Trans i18nKey="searchResults">Search Results</Trans>
              </Button>
            </span>
          ) : null}

          {nextId ? (
            <span className={classes.backbtn}>
              <Link
                to={{
                  pathname: `/${nextPackageType.toLowerCase()}/${nextId}`,
                  search: nextHref,
                }}
              >
                <Button
                  square={true}
                  variant="outlined"
                  classes={{
                    endIcon: classes.navigationIcon,
                  }}
                  endIcon={
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L0.999999 11"
                        stroke="#121212"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  {t("nextResult")}
                </Button>
              </Link>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );

  const buttonGroup = (
    <div className={classes.buttonGroup}>
      <div className={classes.btn}>
        <DownloadButton fileName={fileName}></DownloadButton>
      </div>
      <div className={classes.btn}>
        {entry?.id ? (
          <CiteButton
            articleId={entry?.id}
            apaString={entry?.apaString}
            chicagoString={entry?.chicagoString}
            harvardString={entry?.harvardString}
            mlaString={entry?.mlaString}
            vancouverString={entry?.vancouverString}
            tllString={getTLLString(entry)}
          ></CiteButton>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.btn}>
        <ShareButton></ShareButton>
      </div>
    </div>
  );

  return (
    <div className="body-container">
      {loginSnack ? (
        <Toast status={true} type="success" message="Logged In Successfully" />
      ) : (
        ""
      )}
      <OuterSpinner />
      <NavBarFixed values={searchForm} bglight={true} prefix={alphabetSearch} />
      <div className="main">
        <section className={classes.section + " section"}>
          <Container
            className={clsx(
              isDesktop
                ? showLineNumbers
                  ? "showLineNumbers"
                  : "hideLineNumbers"
                : "",
              "container"
            )}
          >
            <Grid container spacing={4}>
              {stickyFindOnPage}
              <Grid item xs={12} md={9}>
                <div
                  className={
                    classes.sectionMobile +
                    " space-between " +
                    classes.mobileFindOnPage
                  }
                >
                  <FindOnPage
                    key="mobileFindOnPage"
                    highlightClass={
                      constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS
                    }
                    searchWithinClassName=".resultdata"
                    showStickyOnMobile={true}
                    ref={mobileFindOnPageRef}
                  ></FindOnPage>
                </div>
                {topArea}

                <div className={classes.titlearea} ref={lemmaRef}>
                  <div className="results">
                    <Typography
                      variant="h1"
                      className={classes.results}
                      style={{ wordWrap: "anywhere", paddingRight: "50px" }}
                    >
                      {`${entry?.lemma ? entry.lemma[0] : ""}`}
                    </Typography>
                  </div>
                </div>
                {entry?.id && (
                  <EntryMeta
                    author={entry.author}
                    firstLine={entry.firstLine ?? entry.lin}
                    firstPage={entry.firstPage ?? entry.pag}
                    lastPage={entry.lastPage ?? entry.pag}
                    lastLine={entry.lastLine ?? entry.lin}
                    publishYear={
                      entry.publishDate
                        ? new Date(entry.publishDate)?.getFullYear()?.toString()
                        : ""
                    }
                    volume={entry.volume}
                    pars={entry.pars}
                  ></EntryMeta>
                )}
                {buttonGroup}

                {contentLoaded ? (
                  <EntryTabs
                    key="entryTabs"
                    onTabChange={(index) => {
                      setCurrentTab(index);
                    }}
                    citdata={entry?.newCitList}
                    lemmadata={ldata}
                    articledata={entry?.articleView}
                    lemma={entry?.lemma ? entry.lemma[0] : ""}
                    ref={entryTabsRef}
                  />
                ) : (
                  <div className="center">...</div>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                {/* {isDesktop && entry?.id && (
                  <div
                    className={classes.sectionDesktop}
                    style={{
                      marginLeft: "3px",
                      marginTop: "20px",
                      position: "sticky",
                      top: "120px",
                      left: "0px",
                    }}
                  >
                    {currentTab === constants.EntryTabs.LEMMA_TAB ? (
                      <FindOnPage
                        key="pc-lemma-search"
                        highlightClass={
                          constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS
                        }
                        searchWithinClassName=".lemma-search-div"
                      ></FindOnPage>
                    ) : (
                      <FindOnPage
                        key="pc-cit-search"
                        highlightClass={
                          constants.CURRENT_HIGHLIGHTED_SEARCH_TERM_CLASS
                        }
                        searchWithinClassName=".cit-search-div"
                      ></FindOnPage>
                    )}
                  </div>
                )} */}
                <div className={classes.bottomStickyGroup}>
                  <div className={classes.bottomStickyGroupDiv}>
                    <Button
                      disabled={currentTab === constants.EntryTabs.CITE_TAB}
                      color="primary"
                      variant="contained"
                      onClick={(_) => {
                        ReactGA.event({
                          category: "User actions",
                          action: "Line Numbers Buton",
                          label: showLineNumbers ? "Hide" : "Show",
                          nonInteraction: false,
                        });
                        setShowLineNumbers(!showLineNumbers);
                      }}
                      className={
                        classes.lineNumberToggleButton +
                        " " +
                        classes.sectionDesktop +
                        " " +
                        (currentTab === constants.EntryTabs.CITE_TAB
                          ? classes.hidden
                          : "")
                      }
                    >
                      {showLineNumbers ? (
                        <Trans i18nKey="hideLines">Hide lines</Trans>
                      ) : (
                        <Trans i18nKey="showLines">Show lines</Trans>
                      )}
                    </Button>
                    <BackToTop></BackToTop>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Entry;
